import styled from "styled-components";

export const ButtonSecondary = styled.button`
  border-radius: 4px;
  border: 2px solid #0047ba;
  cursor: pointer;
  font-size: 16px;
  padding: 6px 16px;
  color: #0047ba;

  &:hover {
    border: 2px solid #3381ff;
    color: #3381ff;
  }
`;
