const REACT_APP_CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
const REACT_APP_OKTA_DOMAIN = process.env.REACT_APP_OKTA_DOMAIN;
const REACT_APP_JEPC_BASE_URI = process.env.REACT_APP_JEPC_BASE_URI;

export const oktaConfig = {
  clientId: `${REACT_APP_CLIENT_ID}`,
  issuer: `${REACT_APP_OKTA_DOMAIN}`,
  redirectUri: `${REACT_APP_JEPC_BASE_URI}/login/callback`,
  scopes: ["openid", "profile", "email", "jepcroles"],
  pkce: true,
  disableHttpsCheck: false,
};

// test
