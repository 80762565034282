import * as React from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
  updateStepOneFormData,
  getSubFunctionsDropdown,
  getSectorsDropdown,
  getDivisionsDropdown,
  getRegionsDropdown,
  getBusinessUnitsDropdown,
  getMarketUnitsDropdown,
  getWorkLocationsDropdown,
  getFunctionalKpis,
  getSubAreaCode,
  updateStepOneMasterData,
  getCurrentJobTitleFromEC,
} from "../../paqSlice";
import FormSelect from "../../../../app/form-inputs/FormSelect";
import FormText from "../../../../app/form-inputs/FormText";
import FormDatePicker from "../../../../app/form-inputs/FormDatePicker";
import { getTranslated } from "../../../../app/functions/getTranslatedLevel";
import { DROPDOWN_OPTIONS_MAP, TOOLTIPS } from "../../../../app/constants";
import PurposeOfRequest from "../../../../app/form-inputs/PurposeOfRequest";

interface IPaqStepOne {
  updateFormData: Function;
  disabled?: boolean;
}

const PaqStepOne = ({ updateFormData, disabled = false }: IPaqStepOne) => {
  //States
  const formData = useSelector((state: any) => state.paq.formData.stepOne);
  const state = useSelector((state: any) => state.paq);
  const masterData = useSelector((state: any) => state.paq.masterData.stepOne);
  const formLockedFromState = useSelector((state: any) => state.paq.formLocked);
  const formLocked = formLockedFromState || disabled;
  // make sure to update the string "Job Re-Evaluation"
  // in case purpose of request changes for Standalone Job Evaluation
  const disableRightSide =
    formLocked || formData.purposeOfRequestId !== "Job Re-Evaluation";

  //step one dispatch
  const updateState = (payload: object) => {
    updateFormData(updateStepOneFormData(payload));
  };
  const updateMasterDataState = (payload: object) => {
    updateFormData(updateStepOneMasterData(payload));
  }

  //removing below dropdown master data
  const updateBelowDropdownOptions = (key: string) => {
    let belowDropdownOption ={}
    DROPDOWN_OPTIONS_MAP[key].map((element: string)=> {
      belowDropdownOption = {
        ...belowDropdownOption,
        [element]: [] 
      }
    })
    updateMasterDataState(belowDropdownOption);
  }

  React.useEffect(()=>{
    if(state.oktaUserRole === "tr"){
      let payload = {
        incumbentGPID: formData.incumbentGPID,
        requestId: state.requestId
      };
      updateFormData(getCurrentJobTitleFromEC(payload))
    }
  },[])
  //On function change
  React.useEffect(() => {
    if (formData.functionCode != null) {
      //1. get subfunction
      updateFormData(getSubFunctionsDropdown(formData.functionCode));
    }
  }, [formData.functionCode]);
  //On Country change
  React.useEffect(() => {
    //1. drop down generation
    if (formData.countryCode != null) {
      updateBelowDropdownOptions('countryCode');
      updateFormData(getSectorsDropdown(formData.countryCode));
    }
  }, [formData.countryCode]);
  //On sector change change
  React.useEffect(() => {
    if (formData.sectorCode != null) {
      updateBelowDropdownOptions('sectorCode');
      updateFormData(getDivisionsDropdown(formData.sectorCode));
    }
  }, [formData.sectorCode]);
  //On division  change
  React.useEffect(() => {
    if (formData.divisionCode != null) {
      updateBelowDropdownOptions('divisionCode');
      updateFormData(getRegionsDropdown(formData.divisionCode));
    }
  }, [formData.divisionCode]);
  //On region  change
  React.useEffect(() => {
    if (formData.regionCode != null) {
      updateBelowDropdownOptions('regionCode');
      updateFormData(getBusinessUnitsDropdown(formData.regionCode));
    }
  }, [formData.regionCode]);
  //On buisness unit  change
  React.useEffect(() => {
    if (formData.businessUnitCode != null) {
      updateBelowDropdownOptions('businessUnitCode');
      updateFormData(getMarketUnitsDropdown(formData.businessUnitCode));
    }
  }, [formData.businessUnitCode]);
  //On market unit  change
  React.useEffect(() => {
    if (formData.marketUnitCode != null) {
      updateFormData(getWorkLocationsDropdown(formData.marketUnitCode));
    }
  }, [formData.marketUnitCode]);

  return (
    <>
      <div className="step-one-wrapper">
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <PurposeOfRequest
              data={{
                label: getTranslated("step1.purposeOfRequestId") || "Purpose of request",
                value: getTranslated("step1.jobReEvaluation") || "Job re-evaluation",
                name: "purposeOfRequestId",
                readOnly: true,
                required: true,
                multiline: true,
              }}
              updateState={updateState}
            />
            <FormText
              data={{
                label: getTranslated("step1.incumbentName") || "Employee Name",
                value: formData.incumbentName,
                name: "incumbentName",
                readOnly: true,
                required: true,
              }}
              updateState={updateState}
            />
            <FormText
              data={{
                label: getTranslated("step1.incumbentGPID") || "Employee GPID",
                value: formData.incumbentGPID,
                name: "incumbentGPID",
                readOnly: true,
                required: true,
              }}
              updateState={updateState}
            />
            <FormText
              data={{
                label: getTranslated("step1.currentPositionId") || "Current Position ID",
                value: formData.currentPositionId,
                name: "currentPositionId",
                readOnly: true,
                required: true,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.currentEvaluatedLevelId") || "Current Level",
                value: formData.currentEvaluatedLevelId,
                options: masterData.currentEvaluatedLevels,
                name: "currentEvaluatedLevelId",
                readOnly: true,
                required: true,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.currentCompensatedLevelId") || 'Current Compensated Level',
                value: formData.currentCompensatedLevelId,
                options: masterData.currentCompensatedLevels,
                name: "currentCompensatedLevelId",
                readOnly: true,
                toolTipText: getTranslated("tooltips.currentCompensatedLevelId") || TOOLTIPS.currentCompensatedLevelId,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.proposedEvaluatedLevelId") || "Proposed Level",
                value: formData.proposedEvaluatedLevelId,
                options: masterData.proposedLevels,
                name: "proposedEvaluatedLevelId",
                readOnly: true,
                required: true,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.proposedCompensatedLevelId") || "Proposed Compensated Level",
                value: formData.proposedCompensatedLevelId,
                options: masterData.proposedCompensatedLevels,
                name: "proposedCompensatedLevelId",
                readOnly: formLocked,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormDatePicker
              data={{
                label: getTranslated("step1.effectiveDate") || "Target Effective Date",
                value: formData.effectiveDate,
                name: "effectiveDate",
                required: true,
                readOnly: formLocked,
                toolTipText: getTranslated("tooltips.effectiveDate") || TOOLTIPS.effectiveDate,
                blockOneMonthBack: false,
                blockOneDayBack : true,
              }}
              updateState={updateState}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              data={{
                label: getTranslated("step1.functionCode") || "Function",
                value: formData.functionCode,
                options: masterData.functions,
                name: "functionCode",
                readOnly: true,
                required: true,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.subFunctionCode") || "Sub Function",
                value: formData.subFunctionCode,
                options: masterData.subFunctions,
                name: "subFunctionCode",
                readOnly: disableRightSide,
                required: true,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormText
              data={{
                label: getTranslated("step1.jobTitleId") || "Current Job Title",
                value: formData.jobTitleId,
                name: "jobTitleId",
                readOnly: disableRightSide,
                noNumeric: true,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormText
              data={{
                label: getTranslated("step1.proposedJobTitleId") || "New Position Title",
                value: formData.proposedJobTitleId,
                name: "proposedJobTitleId",
                readOnly: formLocked,
                noNumeric: true,
                required: true,
              }}
              updateState={updateState}
              state={state}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.countryCode") || "Country",
                value: formData.countryCode,
                options: masterData.countries,
                name: "countryCode",
                readOnly: disableRightSide,
                required: true,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.sectorCode") || "Sector",
                value: formData.sectorCode,
                options: masterData.sectors,
                name: "sectorCode",
                readOnly: disableRightSide,
                required: true,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.divisionCode") || "Division",
                value: formData.divisionCode,
                options: masterData.divisions,
                name: "divisionCode",
                readOnly: disableRightSide,
                required: true,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.regionCode") || "Region",
                value: formData.regionCode,
                options: masterData.regions,
                name: "regionCode",
                readOnly: disableRightSide,
                required: true,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.businessUnitCode") || "Business Unit",
                value: formData.businessUnitCode,
                options: masterData.businessUnits,
                name: "businessUnitCode",
                readOnly: disableRightSide,
                required: true,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.marketUnitCode") || "Market Unit",
                value: formData.marketUnitCode,
                options: masterData.marketUnits,
                name: "marketUnitCode",
                readOnly: disableRightSide,
                required: true,
              }}
              updateState={updateState}
              isHidden={true}
            />
            <FormSelect
              data={{
                label: getTranslated("step1.workLocationCode") || "Work Location",
                value: formData.workLocationCode,
                options: masterData.workLocations,
                name: "workLocationCode",
                readOnly: disableRightSide,
                required: true,
                toolTipText: getTranslated("tooltips.workLocationCode") || TOOLTIPS.workLocationCode,
              }}
              updateState={updateState}
              isHidden={true}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PaqStepOne;
