export const getFilteredLevels = (levels: any[]) => {
  const allowedLevels = [
    "L01",
    "L02",
    "L03",
    "L04",
    "L05",
    "L06",
    "L07",
    "L08",
    "L09",
    "L10",
    "L11",
    "LG1",
    "LG2",
    "LG3",
    "C04",
    "C05",
    "C06",
    "C07",
    "C08",
  ];
  return levels.filter(element=>allowedLevels.includes(element.name));
};
