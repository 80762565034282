import React from "react";
import Grid from "@mui/material/Grid";
import Functionalkpi from "../../../../app/form-inputs/Functionalkpi";
import FormSelect from "../../../../app/form-inputs/FormSelect";
import StakeholderInteraction from "../../../../app/form-inputs/StakeholderInteraction";
import {
  updateStepThreeFormData,
  getKpiMaster,
  getFunctionalKpis,
  getSubAreaCode,
  updateExternalStakeHoldersName,
} from "../../paqReEvaluateSlice";
import { useSelector } from "react-redux";
import { ROLE_SCOPE_OPTIONS, TOOLTIPS, TRUE_FALSE_OPTIONS } from "../../../../app/constants";
import FormText from "../../../../app/form-inputs/FormText";
import FormTextAreaFullWidth from "../../../../app/form-inputs/FormTextAreaFullWidth";
import { getRoleScopeDescriptionLabel } from "../../../../app/functions/getFieldNameFromKey";
import { hasStakeholderAsExternal } from "../../../../app/functions/hasStakeholderAsExternal";
import { ToolTip } from "../../../../app/form-inputs/ToolTip";
import { useAppDispatch } from "../../../../app/hooks";
import { getTranslated } from "../../../../app/functions/getTranslatedLevel";

interface IPaqReEvaluateStepThree {
  updateFormData: Function;
  disabled?: boolean;
}

const PaqReEvaluateStepThree = ({
  updateFormData,
  disabled = false,
}: IPaqReEvaluateStepThree) => {
  const appDispatch = useAppDispatch();
  //States
  const stepOneFormData = useSelector(
    (state: any) => state.paqReEvaluate.formData.stepOne
  );
  const state = useSelector(
    (state: any) => state.paqReEvaluate
  );
  const formData = useSelector(
    (state: any) => state.paqReEvaluate.formData.stepThree
  );
  const masterData = useSelector(
    (state: any) => state.paqReEvaluate.masterData.stepThree
  );
  const formLockedFromState = useSelector(
    (state: any) => state.paqReEvaluate.formLocked
  );
  const formLocked = formLockedFromState || disabled;

  const stakeholders = useSelector((state: any) => state.paqReEvaluate.formData.stepThree.stakeholder);

  //step three dispatch
  const updateState = (payload: object) => {
    updateFormData(updateStepThreeFormData(payload));
  };

  //Onload use effect
  React.useEffect(() => {
    //1. drop down generation
    updateFormData(getKpiMaster());
  }, []);

  React.useEffect(() => {
    if (formData.subAreaCode == null && masterData.subAreaCode?.length > 0) {
      updateState({
        functionalKPIs: [],
      });
    }
    if (formData.subAreaCode != null) {
      updateFormData(
        getFunctionalKpis({
          functionCode: stepOneFormData.functionCode,
          subFunctionCode: formData.subAreaCode,
        })
      );
    }
  }, [formData.subAreaCode]);

  React.useEffect(() => {
    if (stepOneFormData.functionCode != null) {
      updateFormData(getSubAreaCode(stepOneFormData.functionCode));
      updateFormData(
        getFunctionalKpis({
          functionCode: stepOneFormData.functionCode,
          subFunctionCode: "NA",
        })
      );
    }
  }, [stepOneFormData.functionCode]);

  React.useEffect(() => {
    const shouldUpate = () => {
      if (masterData.functionalKpis.length == 0) return false;
      if (
        masterData.functionalKpis.length - formData.functionalKPIs.length ==
        3
      ) {
        //** Quick fix for functional kpi
        if (formData.functionalKPIs.length == 0) {
          return true;
        }
        return formData.functionalKPIs.some((elem: any, i: number) => {
          return elem.functionalKPI != masterData.functionalKpis[i].value;
        });
      }

      if (formData.functionalKPIs.length != masterData.functionalKpis.length)
        return true;
      else {
        return masterData.functionalKpis.some((elem: any, i: number) => {
          return elem.value != formData.functionalKPIs[i].functionalKPI;
        });
      }
    };
    if (shouldUpate()) {
      let functionalKPIs = masterData.functionalKpis.map((row: any) => {
        return {
          functionalKPI: row.value,
          value: null,
        };
      });
      if (
        functionalKPIs.some((element: any) => element.functionalKPI == "F11")
      ) {
        functionalKPIs = functionalKPIs.slice(0, functionalKPIs.length - 3);
      }
      updateState({
        functionalKPIs: functionalKPIs,
      });
    }
  }, [formData.functionalKPIs, masterData.functionalKpis]);

  React.useEffect(()=>{    
    if(!hasStakeholderAsExternal(stakeholders)){
      appDispatch(updateExternalStakeHoldersName(null))   
      //set externalStakeHoldersName to null so that field is not sent in payload 
      }
  },[appDispatch, formData, formData.externalStakeHoldersName, stakeholders])

  React.useEffect(() => {
    if (formData.roleScope && formData.roleScope === "Global") {
      updateState({ roleScopeDescription: null });
    }
    if (
      formData.crossFunctionalityOfRole !== undefined &&
      formData.crossFunctionalityOfRole === false
    ) {
      updateState({ crossFunctionalityOfRoleExplanation: null });
    }
  }, [formData.roleScope, formData.crossFunctionalityOfRole]);
  
  return (
    <>
      <div className="step-one-wrapper">
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <div className="label" style={{display:'flex', justifyContent:'flex-start'}}>
              <h3>{getTranslated("step3.functionalKpis") || "Business / Functional KPIs"}</h3>
              <div style={{display:'flex', justifyContent:'flex-start', alignItems:'center'}}><ToolTip title={getTranslated("tooltips.functionalKPIs") || TOOLTIPS.functionalKPIs} /></div>              
            </div>
            {masterData.subAreaCode.length != 0 && (
              <div style={{ fontWeight: "bold" }}>
                <FormSelect
                  data={{
                    label: getTranslated("step3.subAreaCode") || "Sub Area",
                    value: formData.subAreaCode,
                    options: masterData.subAreaCode,
                    name: "subAreaCode",
                    required: true,
                    readOnly: formLocked,
                  }}
                  updateState={updateState}
                />
              </div>
            )}
            {/*---Functional Kpi Start here---*/}
            <Functionalkpi
              values={formData.functionalKPIs}
              updateState={updateState}
              roleImpactOptions={masterData.roleImpacts}
              functionalKpiOptions={masterData.functionalKpis}
              data={{ readOnly: formLocked }}
              state={state}
            />
            <div style={{ marginTop: 15 }}>
              <FormTextAreaFullWidth
                lines={4}
                data={{
                  disabled: formLocked,
                  label: getTranslated("step3.comment") || "Comments (if any)",
                  value: formData.comment,
                  name: "comment",
                  placeholder: getTranslated("placeHolder.comment") || "Use this space to leave any comments",
                }}
                updateState={updateState}
                state={state}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <h3>{getTranslated("step3.coreRoleKPIs") || "Core Role KPIs"}</h3>
            <FormSelect
              data={{
                label: getTranslated("step3.roleImpactId") || "Role Impact",
                value: formData.roleImpactId,
                options: masterData.roleImpacts,
                name: "roleImpactId",
                required: true,
                readOnly: formLocked,
                toolTipText: getTranslated("tooltips.roleImpactId") || TOOLTIPS.roleImpactId,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step3.roleScope") || "Role Scope",
                value: formData.roleScope,
                options: ROLE_SCOPE_OPTIONS,
                name: "roleScope",
                required: true,
                readOnly: formLocked,
              }}
              updateState={updateState}
            />
            {formData.roleScope && formData.roleScope != "Global" ? (
              <FormText
                data={{
                  label: getRoleScopeDescriptionLabel(formData.roleScope),
                  value: formData.roleScopeDescription,
                  name: "roleScopeDescription",
                  readOnly: formLocked,
                  required: true,
                }}
                updateState={updateState}
                state={state}
              />
            ) : null}
            <FormSelect
              data={{
                label: getTranslated("step3.yearsOfExpId") || "Years of Experience Required",
                value: formData.yearsOfExpId,
                options: masterData.experienceRanges,
                name: "yearsOfExpId",
                readOnly: formLocked,
                required: true,
                toolTipText: getTranslated("tooltips.yearsOfExpId") || TOOLTIPS.yearsOfExpId,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step3.planningHorizonId") || "Planning Horizon",
                value: formData.planningHorizonId,
                options: masterData.planningHorizons,
                name: "planningHorizonId",
                toolTipText: getTranslated("tooltips.planningHorizonId") || TOOLTIPS.planningHorizonId,
                readOnly: formLocked,
                required: true,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label: getTranslated("step3.impactOnBusinessId") || "Level of Decision Making and Impact on Business",
                value: formData.impactOnBusinessId,
                options: masterData.discussionLevels,
                name: "impactOnBusinessId",
                readOnly: formLocked,
                required: true,
                toolTipText: getTranslated("tooltips.impactOnBusinessId") || TOOLTIPS.impactOnBusinessId,
              }}
              updateState={updateState}
            />
            <StakeholderInteraction
              values={formData.stakeholder}
              stakeholderOptions={masterData.stakeHolderLevels}
              updateState={updateState}
              data={{ readOnly: formLocked }}
            />
            {hasStakeholderAsExternal(stakeholders) ? (
              <FormText
                data={{
                  label: getTranslated("step3.externalStakeHoldersName") || "Name the external stakeholders the role interacts with",
                  value: formData.externalStakeHoldersName,
                  name: "externalStakeHoldersName",
                  required: true,
                  readOnly: formLocked,
                }}
                updateState={updateState}
              />
            ) : null}
            <FormSelect
              data={{
                label: getTranslated("step3.crossFunctionalityOfRole") || "Does the role work cross-functionally?",
                value: formData.crossFunctionalityOfRole,
                options: TRUE_FALSE_OPTIONS,
                name: "crossFunctionalityOfRole",
                readOnly: formLocked,
                required: true,
                toolTipText: getTranslated("tooltips.crossFunctionalityOfRole") || TOOLTIPS.crossFunctionalityOfRole,
              }}
              updateState={updateState}
            />
            {formData.crossFunctionalityOfRole == true ? (
              <div style={{ marginTop: 15 }}>
                <FormTextAreaFullWidth
                  lines={4}
                  data={{
                    disabled: formLocked,
                    label:
                      getTranslated("step3.crossFunctionalityOfRoleExplanation") || "Please explain in what ways the role works cross-functionally",
                    value: formData.crossFunctionalityOfRoleExplanation,
                    options: [],
                    name: "crossFunctionalityOfRoleExplanation",
                    step: "stepTwo",
                    required: true,
                  }}
                  updateState={updateState}
                  state={state}
                />
              </div>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PaqReEvaluateStepThree;
