import i18n from "i18next";

export const getTranslated = (key: string) => {
  const modifiedKey = key.replaceAll(":","").replaceAll("  "," ").trim()
  if (modifiedKey == i18n.t(modifiedKey)) {
    if(`${modifiedKey}.9` == i18n.t(`${modifiedKey}.9`))
      return null;
    return i18n.t(`${modifiedKey}.9`)
  }
  return i18n.t(modifiedKey);
};

export const dynamicValue = (value: string) =>{
  return getTranslated(`dynamic.${value}`) || value
}
