import React from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
  updateStepTwoFormData,
  getRoleContextMaster,
} from "../../paqReEvaluateSlice";
import FormSelect from "../../../../app/form-inputs/FormSelect";
import FormTextRepeat from "../../../../app/form-inputs/FormTextRepeat";
import { TOOLTIPS } from "../../../../app/constants";
import FormTextAreaFullWidth from "../../../../app/form-inputs/FormTextAreaFullWidth";
import { getTranslated } from "../../../../app/functions/getTranslatedLevel";

interface IPaqReEvaluateStepTwo {
  updateFormData: Function;
  disabled?: boolean;
}

const PaqReEvaluateStepTwo = ({ updateFormData, disabled = false }: IPaqReEvaluateStepTwo) => {
  //States
  const formData = useSelector(
    (state: any) => state.paqReEvaluate.formData.stepTwo
  );
  const state = useSelector(
    (state: any) => state.paqReEvaluate
  );
  const masterData = useSelector(
    (state: any) => state.paqReEvaluate.masterData.stepTwo
  );
  const formLockedFromState = useSelector(
    (state: any) => state.paqReEvaluate.formLocked
  );
  const formLocked = formLockedFromState || disabled;

  //step two dispatch
  const updateState = (payload: object) => {
    updateFormData(updateStepTwoFormData(payload));
  };

  //Onload use effect
  React.useEffect(() => {
    //1. drop down generation
    updateFormData(getRoleContextMaster());
  }, []);

  return (
    <>
      <div className="step-2-wrapper">
        <Grid container spacing={10}>
          <Grid item xs={12} md={6}>
            <FormTextAreaFullWidth
              lines={3}
              maxLength={800}
              data={{
                disabled: formLocked,
                label: getTranslated("step2.rolePurpose") || "Role Purpose",
                value: formData.rolePurpose,
                options: [],
                name: "rolePurpose",
                required: true,
                toolTipText: getTranslated("tooltips.rolePurpose") || TOOLTIPS.rolePurpose,
              }}
              updateState={updateState}
              state={state}
            />
            <div className="textField-gap">
              <FormTextAreaFullWidth
                lines={2}
                data={{
                  disabled: formLocked,
                  label: getTranslated("step2.comparableRoles") || "Comparable Roles (optional)",
                  value: formData.comparableRoles,
                  name: "comparableRoles",
                }}
                updateState={updateState}
                state={state}
              />
            </div>
            <FormTextRepeat
              data={{
                disabled: formLocked,
                label: getTranslated("step2.coreAccountability") || "Core Accountabilities",
                placeholder: getTranslated("placeHolder.coreAccountability") || "Accountability",
                value: formData.coreAccountability,
                options: [],
                name: "coreAccountability",
                noOfInput: 6,
                required: true,
                toolTipText: getTranslated("tooltips.coreAccountability") || TOOLTIPS.coreAccountability,
              }}
              updateState={updateState}
              state={state}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormSelect
              data={{
                label: getTranslated("step2.lastEvalDate") || "When was this role last evaluated? (optional)",
                value: formData.lastEvalDate,
                options: masterData.lastEvaluatedYear,
                name: "lastEvalDate",
                readOnly: formLocked,
              }}
              updateState={updateState}
            />
            <FormSelect
              data={{
                label:
                  getTranslated("step2.businessContextForRoleReevaluationId") || "Please select the business context that is the primary driver for re-evaluating this role",
                value: formData.businessContextForRoleReevaluationId,
                options: masterData.businessContexts,
                name: "businessContextForRoleReevaluationId",
                readOnly: formLocked,
                required: true,
              }}
              updateState={updateState}
            />

            <div style={{ marginTop: 15, marginBottom: 15 }}>
              <FormTextAreaFullWidth
                lines={2}
                data={{
                  label: getTranslated("step2.otherDescription") || "Please provide more details on the business context",
                  value: formData.otherDescription,
                  name: "otherDescription",
                  required: true,
                  disabled: formLocked,
                }}
                updateState={updateState}
                state={state}
              />
            </div>

            <FormTextRepeat
              data={{
                disabled: formLocked,
                label:
                  getTranslated("step2.addInAccountability") || "What accountabilities have changed / have been added to this role?",
                placeholder: getTranslated("placeHolder.addInAccountability") || "Change description",
                value: formData.addInAccountability,
                options: [],
                name: "addInAccountability",
                noOfInput: 6,
                required: true,
                toolTipText: getTranslated("tooltips.addInAccountability") || TOOLTIPS.addInAccountability,
              }}
              updateState={updateState}
              state={state}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PaqReEvaluateStepTwo;
