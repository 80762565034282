import { Button, Typography } from "@mui/material";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { getTranslated } from "../functions/getTranslatedLevel";

interface Props {
  stepName: string;
  onEdit: (data: any) => void;
}

export const EditButton = ({ stepName, onEdit }: Props) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: "18px",
        color: "#000000de",
        fontWeight: "bold",
        margin: "1em",
      }}
    >
      <div>
        <CheckCircleIcon fontSize="large" sx={{ color: "#0047ba" }} />
      </div>
      <span style={{ display: "inline-block", paddingRight: "1em" }}>
        {stepName}
      </span>
      <Button
        sx={{
          padding: "4px 16px",
          fontSize: "16px",
          textTransform: "none",
          border: '1px solid #0047ba',
          color: "#0047ba",
          ":hover": {
            border: '1px solid #3381ff',
            color: "#3381ff",
          },
        }}
        disableElevation
        variant="outlined"
        onClick={onEdit}
        startIcon={<EditOutlinedIcon />}
      >
        <Typography>{getTranslated("buttons.edit") || "Edit"}</Typography>
      </Button>
    </div>
  );
};
