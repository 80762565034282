import React, { useEffect } from "react";
import "../../assets/css/header/header.css";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { useAppDispatch } from "../../app/hooks";
import { MenuItem, Select } from "@mui/material";
import { useTranslation } from "react-i18next";
import { getTranslated } from "../../app/functions/getTranslatedLevel";

interface IHeader {
  updateLanguage: Function;
  state: any;
}

const Header = ({ updateLanguage, state }: IHeader) => {
  const { i18n } = useTranslation();
  const appDispatch = useAppDispatch();
  const language = state.language
  const isEnable = state.oktaUserRole == "tr" && state.showLandingPage

  useEffect(() => {
    i18n.changeLanguage(language);
  }, [i18n,language]);

  const languageOptions = [
    {
      name: "English",
      value: "en",
    },
    {
      name: "Español",
      value: "es",
    },
  ];

  const updateFormData = (params: any) => {
    appDispatch(params);
  };

  const updateState = (payload: object) => {
    updateFormData(updateLanguage(payload));
  };

  const handleChange = (key: any, event: any) => {
    //console.log(key, event);
    let value = event.target.value;
    i18n.changeLanguage(value)
    updateState({
      [key]: value,
    });
  };

  return (
    <>
      <header className="header-wrapper">
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={6} md={6}>
              <div className="logo-area">
                {/* <Link to="/"> */}
                  <img src="/images/pepsico-logo.jpg" className="logo" />
                {/* </Link> */}
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              md={6}
              justifyContent="right"
              alignItems="center"
              display={"flex"}
            >
              <div style={{paddingRight: '2em' }}>
                <Select
                  disabled={!isEnable}
                  sx={{
                    backgroundColor:'white',
                    fontWeight: 'bold'
                  }}
                  size="small"
                  id="language"
                  value={language}
                  label=""
                  onChange={(e) => handleChange("language", e)}
                  title={
                    languageOptions.find((o: any) => {
                      return o.value == language;
                    })?.name
                  }
                >
                  {languageOptions.map((item: any, i: number) => {
                    return (
                      <MenuItem value={item.value} key={item.name + i}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </div>
              <div className="site-info">
                <h1 className="site-title">{getTranslated("others.jobEvaluationTool") || "Job Evaluation Tool"}</h1>
              </div>
            </Grid>
          </Grid>
          <div className="form-wrapper"></div>
        </Container>
      </header>
    </>
  );
};

export default Header;
