import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import counterReducer from "../features/counter/counterSlice";
import paqSlice from "../features/paq/paqSlice";
import paqReEvaluateSlice from "../features/paqReEvaluate/paqReEvaluateSlice";
import paqEvaluateNewRoleSlice from "../features/paqEvaluateNewRole/paqEvaluateNewRoleSlice";
import fmSlice from "../features/functionalModels/fm_1/fmOneSlice";
import fmEvaluateNewRoleSlice from "../features/functionalModels/fmPaqEvaluateNewRole/fmOneSlice";
import fmReEvaluateSlice from "../features/functionalModels/fmPaqReEvaluate/fmOneSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    paq: paqSlice,
    paqReEvaluate: paqReEvaluateSlice,
    paqEvaluateNewRole: paqEvaluateNewRoleSlice,
    fm: fmSlice,
    fmEvaluateNewRole: fmEvaluateNewRoleSlice,
    fmReEvaluate: fmReEvaluateSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
