const validate = (value: any) => {
  if (value !== null && value !== "" && value !== 0) {
    return true;
  } else {
    return false;
  }
};

const validateNumberFields = (value: any) => {
  if (value !== null && value !== "") {
    return true;
  } else {
    return false;
  }
};
export const positionDetailsValidate = (payload: any) => {
  let requiredProps = [
    "purposeOfRequestId",
    "newManagerGpid",
    "newManagerName",
    "currentEvaluatedLevelId",
    "proposedLevelId",
    "effectiveDate",
    "functionCode",
    "subFunctionCode",
    "proposedJobTitleId",
    "countryCode",
    "sectorCode",
    "divisionCode",
    "regionCode",
    "businessUnitCode",
    "marketUnitCode",
    "workLocationCode",
  ];
  let result = true;
  if (payload.effectiveDate === "Invalid date") return false;
  requiredProps.forEach(function (item, index) {
    if (!validate(payload[item])) {
      result = false;
    }
  });

  return result;
};

export const paq2roleContextValidate = (payload: any, paqMasterData: any) => {
  let requiredProps = [
    "rolePurpose",
    "businessContextForRoleReevaluationId",
    "otherDescription",
  ];
  let result = true;
  requiredProps.forEach(function (item, index) {
    if (!validate(payload[item])) {
      result = false;
    }
  });

  if (!validate(payload.coreAccountability[0])) return false;

  if (!validate(payload.addInAccountability[0])) return false;

  return result;
};

export const paq2heirarchyValidate = (payload: any) => {
  let requiredProps = [
    "currentStateOrgChartFileName",
    "futureStateOrgChartFileName",
    "changeInRelationship",
    "managerGPID",
    "managerEvaluatedLevelId",
  ];
  let requiredNumberProps = [
    "numberOfDirectReports",
    "numberOfMatrixReport",
    "headCount",
  ]
  let result = true;
  requiredProps.forEach(function (item, index) {
    if (!validate(payload[item])) {
      result = false;
    }
  });
  requiredNumberProps.forEach(function (item, index) {
    if (!validateNumberFields(payload[item])) {
      result = false;
    }
  });

  return result;
};
