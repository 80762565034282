import { ROLE_SCOPE_OPTIONS } from "../../app/constants";
import {
  getFieldNameFromKey,
  getFunctionalKpiFieldNameFromKey,
  getRoleScopeDescriptionLabel,
  getValuetoPassForPDF,
  isCurrencyField,
  isDropdownField,
} from "../../app/functions/getFieldNameFromKey";
import { dynamicValue, getTranslated } from "../../app/functions/getTranslatedLevel";
import { hasStakeholderAsExternal } from "../../app/functions/hasStakeholderAsExternal";

export const getPaqReEvaluatePdfPayload = (
  PaqReEvaluateFormData: any,
  PaqReEvaluateMasterData: any
) => {
  // Master datas
  const positonDetailsMasterData = PaqReEvaluateMasterData.stepOne;
  const roleContextsMasterData = PaqReEvaluateMasterData.stepTwo;
  const kpisMasterData = PaqReEvaluateMasterData.stepThree;
  const hierarchyMasterData = PaqReEvaluateMasterData.stepFour;

  //Form datas
  const positonDetailsFormData = PaqReEvaluateFormData.stepOne;
  const roleContextsFormData = PaqReEvaluateFormData.stepTwo;
  const kpisFormData = PaqReEvaluateFormData.stepThree;
  const hierarchyFormData = PaqReEvaluateFormData.stepFour;

  let functionalKpi: any[] = [];
  kpisFormData.functionalKPIs.forEach((element: any, i: number) => {
    functionalKpi.push({
      value: isCurrencyField(
        kpisMasterData.functionalKpis,
        element.functionalKPI
      )
        ? `$${element.value} MM`
        : isDropdownField(kpisMasterData.functionalKpis, element.functionalKPI)
        ? dynamicValue(getFunctionalKpiFieldNameFromKey(
            kpisMasterData.functionalKpis,
            element.functionalKPI,
            element.value
          ))
        : element.value,
      name: dynamicValue(getFieldNameFromKey(
        kpisMasterData.functionalKpis,
        element.functionalKPI
      )),
      subTitle: getTranslated("step3.functionalKpis") || "Business / Functional KPIs",
    });
  });

  let otherDescriptionObject: any = [];
  if (roleContextsFormData.otherDescription != null) {
    otherDescriptionObject.push({
      value: roleContextsFormData.otherDescription,
      name: getTranslated("step2.otherDescription") || "Please provide more details on the business context",
      subTitle: "",
    });
  }
  let formDetails: any = {};
  formDetails.positionDetails = {
    title: getTranslated(`steps.0`) || "Position Details",
    fields: [
      {
        value: getTranslated("step1.jobReEvaluation") || "Job re-evaluation",
        name: getTranslated("step1.purposeOfRequestId") || "Purpose of request",
        subTitle: "",
      },
      {
        value: positonDetailsFormData.newManagerGpid,
        name: getTranslated("step1.newManagerGpid") || "New Manager GPID",
        subTitle: "",
      },
      {
        value: positonDetailsFormData.newManagerName,
        name: getTranslated("step1.newManagerName") || "New Manager Name",
        subTitle: "",
      },
      {
        value: positonDetailsFormData.currentPositionId,
        name: getTranslated("step1.positionIdOptional") || "Position ID (optional)",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
            positonDetailsMasterData.currentEvaluatedLevels,
            positonDetailsFormData.currentEvaluatedLevelId
          )
        ),
        name: getTranslated("step1.currentEvaluatedLevelId") || "Current Level",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
          positonDetailsMasterData.proposedLevels,
          positonDetailsFormData.proposedEvaluatedLevelId
        )),
        name: getTranslated("step1.proposedEvaluatedLevelId") || "Proposed Evaluated Level",
        subTitle: "",
      },
      {
        value:
          positonDetailsFormData.proposedCompensatedLevelId !== 0
            ? dynamicValue(getFieldNameFromKey(
                positonDetailsMasterData.proposedCompensatedLevels,
                positonDetailsFormData.proposedCompensatedLevelId
              ))
            : "N/A",
            name: getTranslated("step1.proposedCompensatedLevelId") || "Proposed Compensated  Level",
        subTitle: "",
      },
      {
        value: positonDetailsFormData.effectiveDate,
        name: getTranslated("step1.effectiveDate") || "Target Effective Date",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
          positonDetailsMasterData.functions,
          positonDetailsFormData.functionCode
        )),
        name: getTranslated("step1.functionCode") || "Function",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
          positonDetailsMasterData.subFunctions,
          positonDetailsFormData.subFunctionCode
        )),
        name: getTranslated("step1.subFunctionCode") || "Sub Function",
        subTitle: "",
      },
      {
        value: positonDetailsFormData.jobTitleId,
        name: getTranslated("step1.jobTitleId") ||  "Current Job Title",
        subTitle: "",
      },
      {
        value: positonDetailsFormData.proposedJobTitleId,
        name: getTranslated("step1.proposedJobTitleId") || "New Position Title",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
          positonDetailsMasterData.countries,
          positonDetailsFormData.countryCode
        )),
        name: getTranslated("step1.countryCode") || "Country",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
          positonDetailsMasterData.sectors,
          positonDetailsFormData.sectorCode
        )),
        name: getTranslated("step1.sectorCode") || "Sector",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
          positonDetailsMasterData.divisions,
          positonDetailsFormData.divisionCode
        )),
        name: getTranslated("step1.divisionCode") || "Division",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
          positonDetailsMasterData.regions,
          positonDetailsFormData.regionCode
        )),
        name: getTranslated("step1.regionCode") || "Region",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
          positonDetailsMasterData.businessUnits,
          positonDetailsFormData.businessUnitCode
        )),
        name: getTranslated("step1.businessUnitCode") || "Business Unit",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
          positonDetailsMasterData.marketUnits,
          positonDetailsFormData.marketUnitCode
        )),
        name: getTranslated("step1.marketUnitCode") || "Market Unit",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
          positonDetailsMasterData.workLocations,
          positonDetailsFormData.workLocationCode
        )),
        name: getTranslated("step1.workLocationCode") || "Work Location",
        subTitle: "",
      },
    ],
  };
  formDetails.roleContext = {
    title: getTranslated(`steps.1`) || "Role Context",
    fields: [
      {
        value: roleContextsFormData.rolePurpose,
        name: getTranslated("step2.rolePurpose") ||  "Role Purpose",
        subTitle: "",
      },
      {
        value: roleContextsFormData.comparableRoles,
        name: getTranslated("step2.comparableRoles") || "Comparable Roles",
        subTitle: "",
      },
      {
        value: getValuetoPassForPDF(roleContextsFormData.lastEvalDate),
        name: getTranslated("step2.lastEvalDate") ||  "When was this role last evaluated? (optional)",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
          roleContextsMasterData.businessContexts,
          roleContextsFormData.businessContextForRoleReevaluationId
        )),
        name: getTranslated("step2.businessContextForRoleReevaluationId") || "Please select the business context that is the primary driver for re-evaluating this role",
        subTitle: "",
      },
      ...otherDescriptionObject,
      ...roleContextsFormData.coreAccountability.map((element: string) => {
        return {
          value: element,
          name: getTranslated("step2.coreAccountability") || "Core Accountabilities",
          subTitle: "",
        };
      }),
      ...roleContextsFormData.addInAccountability.map((element: string) => {
        return {
          value: element,
          name: getTranslated("step2.addInAccountability") || "What accountabilities have changed / have been added to this role?",
          subTitle: "",
        };
      }),
    ],
  };

  let subAreaCode = [];
  if (kpisMasterData.subAreaCode.length != 0) {
    let subAreaName = kpisMasterData.subAreaCode.find((o: { value: string; }) => o.value === kpisFormData.subAreaCode).name;
    subAreaCode.push({
      value: subAreaName,
      name: getTranslated("step3.subAreaCode") || "Sub Area",
      subTitle: getTranslated("step3.functionalKpis") || "Business / Functional KPIs",
    });
  }
  
  let crossFunctionalityOfRoleExplanation = [];
  if (kpisFormData.crossFunctionalityOfRole == true) {
    crossFunctionalityOfRoleExplanation.push({
      value: kpisFormData.crossFunctionalityOfRoleExplanation,
      name: getTranslated("step3.crossFunctionalityOfRoleExplanation") || "Please explain in what ways the role works cross-functionally",
      subTitle: "",
    });
  }

  let externalStakeHoldersName = [];
  if (hasStakeholderAsExternal(kpisFormData.stakeholder)) {
    externalStakeHoldersName.push({
      value: kpisFormData.externalStakeHoldersName,
      name: getTranslated("step3.externalStakeHoldersName") || "Name the external stakeholders the role interacts with",
      subTitle: "",
    });
  }

  let roleScopeDescription = [];
  if (kpisFormData.roleScope && kpisFormData.roleScope != "Global") {
    roleScopeDescription.push({
      value: kpisFormData.roleScopeDescription,
      name: getRoleScopeDescriptionLabel(kpisFormData.roleScope),
      subTitle: "",
    });
  }
  formDetails.kpis = {
    title: getTranslated(`steps.2`) || "KPIs",
    fields: [
      ...subAreaCode,
      ...functionalKpi,
      {
        value: getValuetoPassForPDF(kpisFormData.comment),
        name: getTranslated("step3.comment") || "Comments (if any)",
        subTitle: getTranslated("step3.functionalKpis") || "Business / Functional KPIs",
      },
      /////////// Add roleImpactId
      {
        value: dynamicValue(getFieldNameFromKey(kpisMasterData.roleImpacts, kpisFormData.roleImpactId)),
        name: getTranslated("step3.roleImpactId") || "Role Impact",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(ROLE_SCOPE_OPTIONS, kpisFormData.roleScope)),
        name: getTranslated("step3.roleScope") || "Role Scope",
        subTitle: "",
      },
      ...roleScopeDescription,
      {
        value: dynamicValue(getFieldNameFromKey(
          kpisMasterData.experienceRanges,
          kpisFormData.yearsOfExpId
        )),
        name: getTranslated("step3.yearsOfExpId") || "Years of Experience Required",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
          kpisMasterData.planningHorizons,
          kpisFormData.planningHorizonId
        )),
        name: getTranslated("step3.planningHorizonId") || "Planning Horizon",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
          kpisMasterData.discussionLevels,
          kpisFormData.impactOnBusinessId
        )),
        name: getTranslated("step3.impactOnBusinessId") || "Level of Decision Making and Impact on Business",
        subTitle: "",
      },
      ...kpisFormData.stakeholder.map((element: any) => {
        return {
          value: `${dynamicValue(getFieldNameFromKey(
            kpisMasterData.stakeHolderLevels,
            element.stakeholderId
          ))} (${dynamicValue(element.frequency)})`,
          name: getTranslated("step3.stackholder") || "Highest Level of Stakeholder Interaction and Frequency",
          subTitle: "",
        };
      }),
      ...externalStakeHoldersName,
      {
        value: kpisFormData.crossFunctionalityOfRole == true ? getTranslated(`dynamic.Yes`) : getTranslated(`dynamic.No`),
        name: getTranslated("step3.crossFunctionalityOfRole") || "Does the role work cross-functionally?",
        subTitle: "",
      },
      ...crossFunctionalityOfRoleExplanation,
    ],
  };
  formDetails.hierarchy = {
    title: getTranslated(`steps.3`) || "Hierarchy",
    fields: [
      {
        value: hierarchyFormData.numberOfDirectReports,
        name: getTranslated("step4.numberOfDirectReports") || "Number of Direct Reports",
        subTitle: "",
      },
      {
        value: hierarchyFormData.numberOfMatrixReport,
        name: getTranslated("step4.numberOfMatrixReport") || "Number of Matrix Reports",
        subTitle: "",
      },
      {
        value: hierarchyFormData.headCount,
        name: getTranslated("step4.headCount") || "Total Team Headcount",
        subTitle: "",
      },
      {
        value: hierarchyFormData.changeInRelationship == true ? getTranslated("dynamic.Yes") : getTranslated("dynamic.No"),
        name: getTranslated("step4.changeInRelationship") || "Has the role changed reporting relationships?",
        subTitle: "",
      },
      {
        value: hierarchyFormData.managerName,
        name: getTranslated("step4.managerName") || "Manager Name",
        subTitle: "",
      },
      {
        value: hierarchyFormData.managerGPID,
        name: getTranslated("step4.managerGPID") || "Manager GPID",
        subTitle: "",
      },
      {
        value: dynamicValue(getFieldNameFromKey(
          hierarchyMasterData.levels,
          hierarchyFormData.managerEvaluatedLevelId
        )),
        name: getTranslated("step4.managerEvaluatedLevelId") || "Manager Evaluated Level",
        subTitle: "",
      },
      {
        value: getValuetoPassForPDF(hierarchyFormData.matrixManagerName),
        name: getTranslated("step4.matrixManagerName") || "Matrix Manager Name",
        subTitle: "",
      },
      {
        value: getValuetoPassForPDF(hierarchyFormData.matrixManagerGPID),
        name: getTranslated("step4.matrixManagerGPID") || "Matrix Manager GPID",
        subTitle: "",
      },
      {
        value: getValuetoPassForPDF(hierarchyFormData.hrManagerName),
        name: getTranslated("step4.hrManagerName") || "HRBP / HR Manager Name",
        subTitle: "",
      },
      {
        value: getValuetoPassForPDF(hierarchyFormData.hrManagerGPID),
        name: getTranslated("step4.hrManagerGPID") || "HRBP / HR Manager GPID",
        subTitle: "",
      },
    ],
  };

  return formDetails;
};
