import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  & > div,
  & > ul {
    flex: 1;
  }

  button {
    margin-left: 4px;
    margin-right: 8px;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media (max-width: ${({ theme }) => theme.mobile}) {
    flex-direction: column;
    text-align: center;
  }
`;
