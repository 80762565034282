import { hasStakeholderAsExternal } from "../../app/functions/hasStakeholderAsExternal";

const validate = (value: any) => {
  if (value !== null && value !== "" && value !== 0) {
    return true;
  } else {
    return false;
  }
};

const validateNumberFields = (value: any) => {
  if (value !== null && value !== "") {
    return true;
  } else {
    return false;
  }
};
export const positionDetailsValidate = (payload: any) => {
  let requiredProps = [
    "purposeOfRequestId",
    "incumbentName",
    "incumbentGPID",
    "currentPositionId",
    "currentEvaluatedLevelId",
    "effectiveDate",
    "functionCode",
    "subFunctionCode",
    "proposedJobTitleId",
    "countryCode",
    "sectorCode",
    "divisionCode",
    "regionCode",
    "businessUnitCode",
    "marketUnitCode",
    "workLocationCode",
  ];
  let result = true;
  if (payload.effectiveDate === "Invalid date") return false;
  requiredProps.forEach(function (item, index) {
    if (!validate(payload[item])) {
      result = false;
    }
  });

  return result;
};

export const roleContextValidate = (payload: any, paqMasterData: any) => {
  let requiredProps = [
    "rolePurpose",
    "businessContextForRoleReevaluationId",
    "otherDescription",
  ];
  let result = true;
  requiredProps.forEach(function (item, index) {
    if (!validate(payload[item])) {
      result = false;
    }
  });

  if (!validate(payload.coreAccountability[0])) return false;

  if (!validate(payload.addInAccountability[0])) return false;

  return result;
};

export const kpisValidate = (payload: any) => {
  
  let requiredProps = ["functionalKPIs", "stakeholder"];
  const additionalrequiredProps = [
    "roleScope",
    "yearsOfExpId",
    "planningHorizonId",
    "impactOnBusinessId",
    "crossFunctionalityOfRole",
    "roleImpactId"
  ];
  let result = true;
  requiredProps.forEach(function (item, index) {
    if (item === "functionalKPIs") {
      payload[item].map((kpi: any, kpiindex: number) => {
        if (!validate(kpi.functionalKPI)) {
          result = false;
        }
        if (!validate(kpi.value)) {
          result = false;
        }
      });
    }
    if (item === "stakeholder") {
      payload[item].map((stakeholder: any, stakeholderindex: number) => {
        if (!validate(stakeholder.stakeholderId)) {
          result = false;
        }
      });
    }
  });

  if (payload.crossFunctionalityOfRole == true) {
    if (!validate(payload.crossFunctionalityOfRoleExplanation)) return false;
  }

  if (payload.roleScope && payload.roleScope != "Global") {
    if (!validate(payload.roleScopeDescription)) {
      return false;
    }
  }  
  
  if (hasStakeholderAsExternal(payload.stakeholder)) {
    if (!validate(payload.externalStakeHoldersName)) {
      return false;
    }
  }

  additionalrequiredProps.forEach(function (item, index) {
    if (!validate(payload[item])) {
      result = false;
    }
  });

  return result;
};

export const heirarchyValidate = (payload: any) => {
  let requiredProps = [
    "currentStateOrgChartFileName",
    "futureStateOrgChartFileName",
    "incumbentName",
    "incumbentGPID",
    "changeInRelationship",
    "managerGPID",
    "managerEvaluatedLevelId",
  ];

  let requiredNumberProps=[
    "numberOfDirectReports",
    "numberOfMatrixReport",
    "headCount",
  ]
  let result = true;
  requiredProps.forEach(function (item, index) {
    if (!validate(payload[item])) {
      result = false;
    }
  });

  requiredNumberProps.forEach(function (item, index) {
    if (!validateNumberFields(payload[item])) {
      result = false;
    }
  });

  return result;
};
