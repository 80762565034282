import Zoom from "@mui/material/Zoom";
import { Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

interface Props {
  title: string;
}

export const ToolTip = ({ title }: Props) => {
  return (
    <Tooltip
      title={<div dangerouslySetInnerHTML={{ __html: title }} />}
      TransitionComponent={Zoom}
      leaveDelay={500}
      componentsProps={{
        tooltip: {
          sx: {
            bgcolor: "#36454F",
            "& .MuiTooltip-arrow": {
              color: "#36454F",
            },
          },
        },
      }}
      arrow
    >
      <HelpOutlineIcon />
    </Tooltip>
  );
};
