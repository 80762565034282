import { TRUE_FALSE_OPTIONS } from "../constants";
import { getTranslated } from "./getTranslatedLevel";

export const getFieldNameFromKey = (searchArray: any, key: any) => {
  let name = "N/A";
  searchArray.forEach((data: any) => {
    if (data.value == key) {
      name = data.name;
    }
  });

  return name;
};

export const getValuetoPassForPDF = (value: any) => {
  if (value == null) return "N/A";
  return value;
};

export const isCurrencyField = (searchArray: any, value: number) => {
  let result = false;
  const functionalKPIValue = value;
  searchArray.map((option: any) => {
    if (option.value == functionalKPIValue && option.valueType == "Currency")
      result = true;
  });
  return result;
};

export const isDropdownField = (searchArray: any, value: number) => {
  let result = false;
  const functionalKPIValue = value;
  searchArray.map((option: any) => {
    if (option.value == functionalKPIValue && option.valueType == "Dropdown")
      result = true;
  });
  return result;
};

export const getFunctionalKpiFieldNameFromKey = (
  functionalKpis: any[],
  functionalKPI: any,
  value: any
) => {
  let funcKPIDropdownValueList = null;
  const functionalKPIValue = functionalKPI;
  functionalKpis.map((option: any) => {
    if (option.value == functionalKPIValue)
      funcKPIDropdownValueList = option.funcKPIDropdownValueList;
  });
  if (funcKPIDropdownValueList == null)
    return getFieldNameFromKey(TRUE_FALSE_OPTIONS, value);
  else return getFieldNameFromKey(funcKPIDropdownValueList, value);
};

export const getRoleScopeDescriptionLabel = (roleScope: string) => {
  const fieldNames: any = {
    "Sector": 'roleScopeDescriptionSector',
    "BU": 'roleScopeDescriptionBU'
  }

  if (roleScope === "Country/Countries")
    return getTranslated('step3.roleScopeDescriptionCountries') || "Name Countries the role supports";
  return getTranslated(`step3.${fieldNames[roleScope]}`) || `Name ${roleScope}(s) the role supports`;
};