import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchDraftedData,
  fetchPreDefinedData,
  fetchPreDefinedDataPositionId,
  fetchUserNameFromGpid,
  fetchPositionDetailsMaster,
  fetchPositionDetails,
  postPositionDetails,
  fetchSubFunctionsDropdown,
  fetchSectorsDropdown,
  fetchDivisionsDropdown,
  fetchRegionsDropdown,
  fetchBusinessUnitsDropdown,
  fetchMarketUnitsDropdown,
  fetchWorkLocationsDropdown,
  fetchRoleContextMaster,
  postRoleContext,
  fetchKpiMaster,
  fetchFunctionalKpis,
  postKpi,
  postHierarchy,
  fetchFutureJobCode,
  postLockUnlockStatus,
  fetchTrAdditionalDetails,
  postTrHr,
  postGeneratePaqPdf,
  fetchFormStatus,
  fetchQueryParams,
  fetchSubAreaCode,
} from "./paqReEvaluateAPI";
import moment from "moment";
import {
  findLevelObjByName,
  successNotice,
  errorNotice,
  showCaseId,
  getProposedCompensatedLevelsArray,
  sendEmail,
} from "../../app/functions/CommonFunctions";
import {
  uploadCurrentOrganizationChart,
  uploadFutureOrganizationChart,
} from "../paq/paqAPI";
import {  
  StringForFileUpload,
} from "../../app/constants";
import { getTranslated } from "../../app/functions/getTranslatedLevel";

//*** INITIAL STATE ****
const initialState = {
  stepOneEditable: true,
  requestOfForm: "",
  authorize: false,
  authorizationFailed: false,
  clickedOnDraft: true,
  isReviewAndSubmit: false,
  paqActiveStep: 0,
  paqProgress: 25,
  formLoader: false,
  formLoaderEC: false,
  trFormSubmitted: false,
  formLocked: false,
  hasAccessToLastPage: false,
  showLastPage: false,
  showLandingPage: true,
  futureJobCodeReadonly: false,
  pdfGenerated: false,
  fromSummary: false,
  //Basic data
  requestId: "",
  gpId: null,
  status: "",
  userId: "",
  oktaUserData: null,
  oktaUserRole: null,
  caseId: "",
  formStatusFetched: false,
  caseIdFetched: "",
  language: 'en',
  masterData: {
    stepOne: {
      purposeOfRequests: [],
      currentEvaluatedLevels: [],
      currentCompensatedLevels: [],
      proposedLevels: [],
      functions: [],
      subFunctions: [],
      jobTitles: [],
      countries: [],
      sectors: [],
      divisions: [],
      regions: [],
      businessUnits: [],
      marketUnits: [],
      workLocations: [],
      proposedCompensatedLevels: [],
    },
    stepTwo: { businessContexts: [], lastEvaluatedYear: [] },
    stepThree: {
      subAreaCode: [],
      functionalKpis: [],
      roleImpacts: [],
      experienceRanges: [],
      planningHorizons: [],
      discussionLevels: [],
      stakeHolderLevels: [],
    },
    stepFour: { levels: [] },
  },
  formData: {
    //Position details
    stepOne: {
      purposeOfRequestId: 2,
      newManagerName: null,
      newManagerGpid: null,
      currentPositionId: null,
      currentEvaluatedLevelId: null,
      proposedEvaluatedLevelId: null,
      proposedCompensatedLevelId: null,
      effectiveDate: null, //moment(new Date()).format("MMMM D,Y"),
      functionCode: null,
      subFunctionCode: null,
      jobTitleId: null,
      proposedJobTitleId:null,
      countryCode: null,
      sectorCode: null,
      divisionCode: null,
      regionCode: null,
      businessUnitCode: null,
      marketUnitCode: null,
      workLocationCode: null,
    },
    //Role context
    stepTwo: {
      comparableRoles: "",
      coreAccountability: [""],
      addInAccountability: [""],
      rolePurpose: "",
      lastEvalDate: null, //moment(new Date()).format("MMMM D,Y"),
      businessContextForRoleReevaluationId: null,
      otherDescription: null,
    },
    //KPIs
    stepThree: {
      subAreaCode: null,
      functionalKPIs: [
      ],
      roleImpactId:null,
      comment: null,
      roleScope: null,
      roleScopeDescription: null,
      yearsOfExpId: null,
      planningHorizonId: null,
      impactOnBusinessId: null,
      stakeholder: [
        {
          stakeholderId: null,
          frequency: "yearly",
        },
      ],
      externalStakeHoldersName:null,
      crossFunctionalityOfRole: null, //yes=> true,no=> false
      crossFunctionalityOfRoleExplanation: null,
    },
    //
    stepFour: {
      currentStateOrgChartFileName: "",
      futureStateOrgChartFileName: "",
      numberOfDirectReports: 0,
      numberOfMatrixReport: 0,
      headCount: 0,
      changeInRelationship: false,
      managerName: null,
      managerGPID: null,
      managerEvaluatedLevelId: null,
      managerCompensationLevelId: null,
      matrixManagerName: null,
      matrixManagerGPID: null,
      hrManagerName: null,
      hrManagerGPID: null,
    },
    trEvaluationStep: {
      submittedDate: "",
      requestorName: "",
      requestSubmissionDate: "",
      snowCaseId: "",
      evaluatedLevelId: null,
      futureJobCode: "",
      evaluationMethod: "Hay Method",
      evaluatedBy: "Total Rewards",
    },
  },
};

//Aysnc methods
// FOR GENERAL PURPOSE
export const getDraftedData = createAsyncThunk(
  "paqReEvaluate/getDraftedData",
  async (requestId: any) => {
    const response = await fetchDraftedData(requestId);
    let stepOne = response.positionDetails;
    let stepTwo = response.roleContext;
    let stepThree = response.kpis;
    let stepFour = response.hierarchy;
    let payload: any = {
      stepOne: null,
      stepTwo: null,
      stepThree: null,
      stepFour: null,
    };
    if (stepOne !== null) {
      payload["stepOne"] = stepOne;
    }
    if (stepTwo !== null) {
      payload["stepTwo"] = stepTwo;
    }
    if (stepThree !== null) {
      payload["stepThree"] = stepThree;
    }
    if (stepFour !== null) {
      payload["stepFour"] = stepFour;
    }
    return payload;
  }
);
// For prepopulated data first time
// using GPID
export const getPreDefinedData = createAsyncThunk(
  "paqReEvaluate/getPreDefinedData",
  async (funPayload: any) => {
    const response = await fetchPreDefinedData(
      funPayload.incumbentGPID,
      funPayload.requestId
    );
    let positionDetails = response.positionDetailsPrePopulated;
    let hierarchyDetails = response.hierarchyPrePopulated;
    let currentJobTitle = response.currentJobTitle;
    let payload: any = { stepOne: null, stepFour: null, currentJobTitle: currentJobTitle };
    if (positionDetails !== null) {
      payload["stepOne"] = {
        //** currentEvaluatedLevelId: positionDetails.currentEvaluatedLevel, Job title not update using GPID
        //** jobTitleId: positionDetails.jobTitle,  Job title not update using GPID
        countryCode: positionDetails.country,
        sectorCode: positionDetails.sector,
        divisionCode: positionDetails.division,
        regionCode: positionDetails.region,
        businessUnitCode: positionDetails.businessUnit,
        marketUnitCode: positionDetails.marketUnit,
        workLocationCode: positionDetails.workLocation,
        newManagerGpid: positionDetails.incumbentGPID
      };
    }
    if (hierarchyDetails !== null) {
      payload["stepFour"] = {
        numberOfDirectReports: hierarchyDetails.directReportNumber,
        numberOfMatrixReport: hierarchyDetails.matrixReportNumber,
        headCount: hierarchyDetails.totalTeamHeadcount,
        //*(update manager details only from newManagerGpid) managerName: hierarchyDetails.managerName,
        //*(update manager details only from newManagerGpid) managerGPID: hierarchyDetails.managerGPID,
        managerEvaluatedLevelId: hierarchyDetails.managerEvaulatedLevel,
        matrixManagerGPID: hierarchyDetails.matrixManagerGPID,
        hrManagerName: hierarchyDetails.hrbpOrHrManagerName,
        hrManagerGPID: hierarchyDetails.hrbpOrHrManagerGPID,
        managerGPID: positionDetails.incumbentGPID        
      };
    }

    //update function sub function based on new manager gpid
    if (funPayload.populateAll != undefined && funPayload.populateAll) {
      payload.stepOne = {
        ...payload.stepOne,
        ...{
          currentEvaluatedLevelId: null, //This only for paq 2 new manager gpid should not populate
          functionCode: positionDetails.function,
          subFunctionCode: positionDetails.subFunction,
        },
      };
    }

    //Not effect job title if new manager gpid change
    if (funPayload.fromManagerGpid != undefined && funPayload.fromManagerGpid) {
      //set current evaluted level as manager evaluted level
      payload.stepFour.managerEvaluatedLevelId =
        positionDetails.currentEvaluatedLevel;

      delete payload.stepOne.currentEvaluatedLevelId;
      delete payload.stepOne.jobTitleId;
    }

    return payload;
  }
);
// using position id
export const getPreDefinedDataPositionId = createAsyncThunk(
  "paqReEvaluate/getPreDefinedDataPositionId",
  async (funPayload: any) => {
    const response = await fetchPreDefinedDataPositionId(
      funPayload.positionId,
      funPayload.requestId
    );
    let positionDetails = response.positionDetailsPrePopulated;
    let hierarchyDetails = response.hierarchyPrePopulated;
    let currentJobTitle = response.currentJobTitle;
    let payload: any = { stepOne: null, stepFour: null, currentJobTitle: currentJobTitle };
    if (positionDetails !== null) {
      payload["stepOne"] = {
        currentEvaluatedLevelId: positionDetails.currentEvaluatedLevel,
        jobTitleId: positionDetails.jobTitle,
        countryCode: positionDetails.country,
        sectorCode: positionDetails.sector,
        divisionCode: positionDetails.division,
        regionCode: positionDetails.region,
        businessUnitCode: positionDetails.businessUnit,
        marketUnitCode: positionDetails.marketUnit,
        workLocationCode: positionDetails.workLocation,
      };
    }
    if (hierarchyDetails !== null) {
      payload["stepFour"] = {
        numberOfDirectReports: hierarchyDetails.directReportNumber,
        numberOfMatrixReport: hierarchyDetails.matrixReportNumber,
        headCount: hierarchyDetails.totalTeamHeadcount,
        //*(update manager details only from newManagerGpid) managerName: hierarchyDetails.managerName,
        //*(update manager details only from newManagerGpid) managerGPID: hierarchyDetails.managerGPID,
        managerEvaluatedLevelId: hierarchyDetails.managerEvaulatedLevel,
        matrixManagerGPID: hierarchyDetails.matrixManagerGPID,
        hrManagerName: hierarchyDetails.hrbpOrHrManagerName,
        hrManagerGPID: hierarchyDetails.hrbpOrHrManagerGPID,
      };
    }
    return payload;
  }
);

//get user name from GPID
export const getUserNameFromGpid = createAsyncThunk(
  "paqReEvaluate/getUserNameFromGpid",
  async (gpid: any) => {
    const response = await fetchUserNameFromGpid(gpid);
    let payload: any = { stepOne: null, stepFour: null };
    payload["stepOne"] = {
      newManagerName: response.managerName,
    };
    payload["stepFour"] = {
      managerName: response.managerName,
    };
    return payload;
  }
);

export const getCurrentJobTitleFromEC = createAsyncThunk(
  "paq/getCurrentJobTitleFromEC",
  async (funPayload: any) => {
    const response = await fetchPreDefinedData(
      funPayload.incumbentGPID,
      funPayload.requestId
    );
    let payload = response.currentJobTitle
    return payload;
  }
);
//1. FOR POSITION DETAILS
export const getPositionDetailsMaster = createAsyncThunk(
  "paqReEvaluate/getPositionDetailsMaster",
  async () => {
    const response = await fetchPositionDetailsMaster();
    //create payload to update
    let updatePayload = {
      stepOne: {
        purposeOfRequests: response.requestPurposeList,
        currentEvaluatedLevels: response.levelsList,
        currentCompensatedLevels: response.levelsList,
        proposedLevels: response.levelsList,
        functions: response.functionsList,
        jobTitles: response.jobTitlesList,
        countries: response.countriesList,
      },
      stepFour: {
        levels: response.levelsList,
      },
    };
    return updatePayload;
  }
);
export const getPositionDetails = createAsyncThunk(
  "paqReEvaluate/getPositionDetails",
  async (gpid: any) => {
    const response = await fetchPositionDetails(gpid);
    //create payload to update
    let positionDetails = response.positionDetailsPrePopulated;
    let payload = {
      purposeOfRequestId: positionDetails.purposeOfRequest,
      currentPositionId: positionDetails.currentPositionID,
      currentEvaluatedLevelId: positionDetails.currentEvaluatedLevel,
      functionCode: positionDetails.function,
      subFunctionCode: positionDetails.subFunction,
      countryCode: positionDetails.country,
      sectorCode: positionDetails.sector,
      divisionCode: positionDetails.division,
      regionCode: positionDetails.region,
      businessUnitCode: positionDetails.businessUnit,
      marketUnitCode: positionDetails.marketUnit,
      workLocationCode: positionDetails.workLocation,
    };
    return payload;
  }
);

export const getSubFunctionsDropdown = createAsyncThunk(
  "paqReEvaluate/getSubFunctionsDropdown",
  async (functionCode: any) => {
    const response = await fetchSubFunctionsDropdown(functionCode);
    return response;
  }
);

export const submitPositionDetails = createAsyncThunk(
  "paqReEvaluate/submitPositionDetails",
  async (payload: any) => {
    const response = await postPositionDetails(payload);
    return response;
  }
);

export const getSectorsDropdown = createAsyncThunk(
  "paqReEvaluate/getSectorsDropdown",
  async (countryCode: any) => {
    const response = await fetchSectorsDropdown(countryCode);
    return response;
  }
);
export const getDivisionsDropdown = createAsyncThunk(
  "paqReEvaluate/getDivisionsDropdown",
  async (sectorCode: any) => {
    const response = await fetchDivisionsDropdown(sectorCode);
    return response;
  }
);
export const getRegionsDropdown = createAsyncThunk(
  "paqReEvaluate/getRegionsDropdown",
  async (sectorCode: any) => {
    const response = await fetchRegionsDropdown(sectorCode);
    return response;
  }
);
export const getBusinessUnitsDropdown = createAsyncThunk(
  "paqReEvaluate/getBusinessUnitsDropdown",
  async (regionCode: any) => {
    const response = await fetchBusinessUnitsDropdown(regionCode);
    return response;
  }
);
export const getMarketUnitsDropdown = createAsyncThunk(
  "paqReEvaluate/getMarketUnitsDropdown",
  async (businessUnitCode: any) => {
    const response = await fetchMarketUnitsDropdown(businessUnitCode);
    return response;
  }
);
export const getWorkLocationsDropdown = createAsyncThunk(
  "paqReEvaluate/getWorkLocationsDropdown",
  async (marketUnitCode: any) => {
    const response = await fetchWorkLocationsDropdown(marketUnitCode);
    return response;
  }
);
//2. FOR ROLE CONTEXT
export const getRoleContextMaster = createAsyncThunk(
  "paqReEvaluate/getRoleContextMaster",
  async () => {
    const response = await fetchRoleContextMaster();
    //create payload to update
    let lastEvaluatedYearList = response.lastEvaluatedYearList.map(
      (option: any) => {
        return {
          value: option.name,
          name: option.name,
        };
      }
    );
    let updatePayload = {
      businessContexts: response.businessContextsList,
      lastEvaluatedYear: lastEvaluatedYearList,
    };
    return updatePayload;
  }
);
export const submitRoleContext = createAsyncThunk(
  "paqReEvaluate/submitRoleContext",
  async (payload: any) => {
    const response = await postRoleContext(payload);
    return response;
  }
);
//2. FOR KPI
export const getKpiMaster = createAsyncThunk(
  "paqReEvaluate/getKpiMaster",
  async () => {
    const response = await fetchKpiMaster();
    //create payload to update
    let updatePayload = {
      roleImpacts: response.roleImpactsList,
      experienceRanges: response.experienceRangeList,
      planningHorizons: response.planningHorizonsList,
      discussionLevels: response.discussionLevelsList,
      stakeHolderLevels: response.stakeHolderLevelsList,
    };
    return updatePayload;
  }
);

export const getFunctionalKpis = createAsyncThunk(
  "paqReEvaluate/getFunctionalKpis",
  async (payload: any) => {
    const response = await fetchFunctionalKpis(payload);
    return response;
  }
);

export const getSubAreaCode = createAsyncThunk(
  "paqReEvaluate/getSubAreaCode",
  async (functionCode: any) => {
    const response = await fetchSubAreaCode(functionCode);
    return response;
  }
);

export const submitKpi = createAsyncThunk(
  "paqReEvaluate/submitKpi",
  async (payload: any) => {
    const response = await postKpi(payload);
    return response;
  }
);
//3. FOR HIARERCHY
export const getHierarchyMaster = createAsyncThunk(
  "paqReEvaluate/getHierarchyMaster",
  async () => {
    const response = await fetchPositionDetailsMaster();
    //create payload to update
    let updatePayload = {
      levels: response.levelsList,
    };
    return updatePayload;
  }
);

export const submitHierarchy = createAsyncThunk(
  "paqReEvaluate/submitHierarchy",
  async (payload: any) => {
    const response = await postHierarchy(payload);
    return response;
  }
);

export const submitPaq = createAsyncThunk(
  "paqReEvaluate/submitPaq",
  async (payload: any) => {
    const response = await postHierarchy(payload);
    return response;
  }
);

export const submitHierarchyNoToast = createAsyncThunk(
  "paqReEvaluate/submitHierarchy(no-toast)",
  async (payload: any) => {
    const response = await postHierarchy(payload);
    return response;
  }
);

export const submitCurrentOrganizationChart = createAsyncThunk(
  "paqReEvaluate/submitCurrentOrganizationChart",
  async (payload: any) => {
    const response = await uploadCurrentOrganizationChart(payload);
    return response;
  }
);
export const submitFutureOrganizationChart = createAsyncThunk(
  "paqReEvaluate/submitFutureOrganizationChart",
  async (payload: any) => {
    const response = await uploadFutureOrganizationChart(payload);
    return response;
  }
);

// chenge Lock / Unlock status
export const updateFormLockStatus = createAsyncThunk(
  "paqReEvaluate/updateFormLockStatus",
  async (payload: any) => {
    const response = await postLockUnlockStatus(payload);
    return response;
  }
);
// for TR Additional Details
export const getTrAdditionalDetails = createAsyncThunk(
  "paqReEvaluate/getTrAdditionalDetails",
  async (trAdditionalDetailsPayload: any) => {
    const response = await fetchTrAdditionalDetails(
      trAdditionalDetailsPayload.functionCode,
      trAdditionalDetailsPayload.subFunctionCode,
      trAdditionalDetailsPayload.requestId
    );
    return response;
  }
);
// for Future Job Code
export const getFutureJobCode = createAsyncThunk(
  "paqReEvaluate/getFutureJobCode",
  async (futureJobCodePayload: any) => {
    const response = await fetchFutureJobCode(
      futureJobCodePayload.functionCode,
      futureJobCodePayload.subFunctionCode,
      futureJobCodePayload.evaluatedLevelId,
      futureJobCodePayload.requestId
    );
    return response;
  }
);
// save TR details
export const createTrHr = createAsyncThunk(
  "paqReEvaluate/createTrHr",
  async (payload: any) => {
    const response = await postTrHr(payload);
    return response;
  }
);

export const generatePaqPdf = createAsyncThunk(
  "paqReEvaluate/generatePaqPdf",
  async (payload: any) => {
    const response = await postGeneratePaqPdf(payload);
    return response;
  }
);

export const getFormStatus = createAsyncThunk(
  "paqReEvaluate/getFormStatus",
  async (requestId: any) => {
    const response = await fetchFormStatus(requestId);
    return response;
  }
);

// get query params from api
export const getQueryParams = createAsyncThunk(
  "paqReEvaluate/getQueryParams",
  async (payload: any) => {
    const response = await fetchQueryParams(payload);
    return response;
  }
);

//*** CREATE SLICE */
export const paqReEvaluateSlice: any = createSlice({
  name: "paqReEvaluate",
  initialState,
  reducers: {
    updateClickedOnDraft: (state, action: PayloadAction<boolean>) => {
      state.clickedOnDraft = action.payload;
    },
    updateIsReviewAndSubmit: (state, action: PayloadAction<boolean>) => {
      state.isReviewAndSubmit = action.payload;
    },
    updateExternalStakeHoldersName: (state, action: PayloadAction<any>) => {
      state.formData.stepThree.externalStakeHoldersName = action.payload;
    },
    nextStep: (state) => {
      state.paqActiveStep += 1;
      state.paqProgress += 25;
    },
    prevStep: (state) => {
      state.paqActiveStep -= 1;
      state.paqProgress -= 25;
    },
    goToLastPage: (state, action: PayloadAction<any>) => {
      state.showLastPage = action.payload;
    },
    goFromLandingPageToForm: (state) => {
      state.showLandingPage = false;
      state.paqActiveStep = 0;
      state.paqProgress = 25;
    },
    goToLandingPage: (state) => {
      state.showLandingPage = true;
    },
    viewForm: (state) => {
      state.paqActiveStep = 0;
      state.paqProgress = 25;
    },
    //update reqId
    updateReqId: (state, action: PayloadAction<any>) => {
      state.requestId = action.payload;
    },
    updateUserId: (state, action: PayloadAction<any>) => {
      state.userId = action.payload;
    },
    updateOktaUserData: (state, action: PayloadAction<any>) => {
      state.oktaUserData = action.payload;
    },
    updateOktaUserRole: (state, action: PayloadAction<any>) => {
      state.oktaUserRole = action.payload;
    },
    updatePurposeOfRequestId: (state, action: PayloadAction<any>) => {
      state.formData.stepOne.purposeOfRequestId = action.payload;
    },
    updateProposedEvaluatedLevelId: (state, action: PayloadAction<any>) => {
      state.formData.stepOne.proposedEvaluatedLevelId = action.payload;
      state.formData.stepOne.proposedCompensatedLevelId = action.payload;
    },
    updateFunctionCode: (state, action: PayloadAction<any>) => {
      state.formData.stepOne.functionCode = action.payload;
    },
    updateSubFunctionCode: (state, action: PayloadAction<any>) => {
      state.formData.stepOne.subFunctionCode = action.payload;
    },
    //Update master data
    updateStepOneMasterData: (state, action: PayloadAction<any>) => {
      state.masterData.stepOne = {
        ...state.masterData.stepOne,
        ...action.payload,
      };
    },
    //Update form data
    updateStepOneFormData: (state, action: PayloadAction<any>) => {
      // {name:"souvik"}
      state.formData.stepOne = {
        ...state.formData.stepOne,
        ...action.payload,
      };
    },
    updateStepTwoFormData: (state, action: PayloadAction<any>) => {
      // {name:"souvik"}
      state.formData.stepTwo = {
        ...state.formData.stepTwo,
        ...action.payload,
      };
    },
    updateStepThreeFormData: (state, action: PayloadAction<any>) => {
      // {name:"souvik"}
      state.formData.stepThree = {
        ...state.formData.stepThree,
        ...action.payload,
      };
    },
    updateStepFourFormData: (state, action: PayloadAction<any>) => {
      // {name:"souvik"}
      state.formData.stepFour = {
        ...state.formData.stepFour,
        ...action.payload,
      };
    },
    updateTrEvaluationStepFormData: (state, action: PayloadAction<any>) => {
      // {name:"souvik"}
      state.formData.trEvaluationStep = {
        ...state.formData.trEvaluationStep,
        ...action.payload,
      };
    },
    updateFormLocked: (state, action: PayloadAction<any>) => {
      state.formLocked = action.payload;
    },
    // ------------------------- to be deleted --------------------
    updateAccessToLastPage: (state, action: PayloadAction<any>) => {
      state.hasAccessToLastPage = action.payload;
    },
    updateAuthorize: (state, action: PayloadAction<any>) => {
      state.authorize = action.payload;
    },
    updatePaqActiveStep: (state, action: PayloadAction<any>) => {
      state.fromSummary = true;
      state.paqActiveStep = action.payload;
      state.paqProgress = (action.payload + 1) * 25;
    },
    skipLandingPage: (state) => {
      state.showLandingPage = false;
    },
    updateStepNumber: (state, action: PayloadAction<any>) => {
      state.paqActiveStep = action.payload;
      state.paqProgress = (action.payload + 1) * 25;
    },
    updateLanguage: (state, action) => {
      state.language = action.payload.language
    },
  },

  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  extraReducers: (builder) => {
    builder
      //FOR GENERAL
      //1 Drafted data fetch
      .addCase(getDraftedData.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getDraftedData.fulfilled,
        (state, action: PayloadAction<any>) => {
          let payload = action.payload;

          //Update stepOne state
          if (payload.stepOne !== null) {
            state.stepOneEditable = false;
            payload.stepOne.effectiveDate = moment(
              new Date(payload.stepOne.effectiveDate)
            ).format("MMMM D,Y");
            state.formData.stepOne = {
              ...state.formData.stepOne,
              ...payload.stepOne,
            };
            state.formData.trEvaluationStep = {
              ...state.formData.trEvaluationStep,
              submittedDate: moment(
                new Date(payload.stepOne.submittedDate)
              ).format("MMMM D,Y"),
            };
            state.status = payload.stepOne.status;
            state.formLocked =
              payload.stepOne?.status === "Submitted" || payload.stepOne?.status === "Completed" ? true : false;
          }

          //Update stepTwo state
          if (payload.stepTwo !== null) {
            let stepTwo = {...payload.stepTwo}
            delete stepTwo.numberOfDirectReports;
            state.formData.stepTwo = {
              ...state.formData.stepTwo,
              ...stepTwo,
            };
          }

          //Update stepThree state
          if (payload.stepThree !== null) {
            state.formData.stepThree = {
              ...state.formData.stepThree,
              ...payload.stepThree,
            };
            if (state.formData.stepThree.functionalKPIs == null) {
              state.formData.stepThree.functionalKPIs = [];
            }
          }

          //Update stepFour state
          if (payload.stepFour !== null) {
            state.formData.stepFour = {
              ...state.formData.stepFour,
              ...payload.stepFour,
            };
          }

          //Loader
          state.formLoader = false;
        }
      )
      .addCase(getDraftedData.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch drafted data api");
        //show error
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.cannotFetchDrafted') || 'Cannot fetch drafted data, please try after sometime'
        );
      })
      //1 prepopulated data fetch
      // using GPID
      .addCase(getPreDefinedData.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getPreDefinedData.fulfilled,
        (state, action: PayloadAction<any>) => {
          let payload = action.payload;
          if (payload.stepOne !== null) {
            state.stepOneEditable = false;
            //StepOne level set
            if (
              payload.stepOne.currentEvaluatedLevelId &&
              payload.stepOne.currentEvaluatedLevelId != null
            ) {
              payload.stepOne.currentEvaluatedLevelId = findLevelObjByName(
                payload.stepOne.currentEvaluatedLevelId,
                state.masterData.stepOne.currentEvaluatedLevels
              ).value;
            }
            //Update stepOne state
            state.formData.stepOne = {
              ...state.formData.stepOne,
              ...payload.stepOne,
            };
          } else {
            let makeNull = {
              currentEvaluatedLevelId: null,
              jobTitleId: null,
              countryCode: null,
              sectorCode: null,
              divisionCode: null,
              regionCode: null,
              businessUnitCode: null,
              marketUnitCode: null,
              workLocationCode: null,
            };
            state.stepOneEditable = true;

            //Update stepOne state
            state.formData.stepOne = {
              ...state.formData.stepOne,
              ...makeNull,
            };
          }

          if(state.language =='es' && payload.currentJobTitle && payload.currentJobTitle.nameEsMx)
            state.formData.stepOne.jobTitleId = payload.currentJobTitle.nameEsMx

          if (payload.stepFour !== null) {
            //StepFour level set
            payload.stepFour.managerEvaluatedLevelId = findLevelObjByName(
              payload.stepFour.managerEvaluatedLevelId,
              state.masterData.stepFour.levels
            ).value;

            //Update stepFour state
            state.formData.stepFour = {
              ...state.formData.stepFour,
              ...payload.stepFour,
            };
          }

          //Loader
          state.formLoader = false;
        }
      )
      .addCase(getPreDefinedData.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch prepopulated data api");
        //show error
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.cannotFetchECData') || 'Cannot fetch prepopulated EC data, please try after sometime'
        );
      })
      // using position Id
      .addCase(getPreDefinedDataPositionId.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getPreDefinedDataPositionId.fulfilled,
        (state, action: PayloadAction<any>) => {
          let payload = action.payload;

          //StepOne level set
          if (payload.stepOne !== null) {
            state.stepOneEditable = false;
            payload.stepOne.currentEvaluatedLevelId = findLevelObjByName(
              payload.stepOne.currentEvaluatedLevelId,
              state.masterData.stepOne.currentEvaluatedLevels
            ).value;

            //Update stepOne state
            state.formData.stepOne = {
              ...state.formData.stepOne,
              ...payload.stepOne,
            };
          } else {
            state.stepOneEditable = true;
          }

          if(state.language =='es' && payload.currentJobTitle && payload.currentJobTitle.nameEsMx)
            state.formData.stepOne.jobTitleId = payload.currentJobTitle.nameEsMx

          if (payload.stepFour !== null) {
            //StepFour level set
            payload.stepFour.managerEvaluatedLevelId = findLevelObjByName(
              payload.stepFour.managerEvaluatedLevelId,
              state.masterData.stepFour.levels
            ).value;

            //Update stepFour state
            state.formData.stepFour = {
              ...state.formData.stepFour,
              ...payload.stepFour,
            };
          }

          //Loader
          state.formLoader = false;
        }
      )
      .addCase(getPreDefinedDataPositionId.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch prepopulated data api");
        //show error
        errorNotice(
          "Error !!",
          "Cannot fetch data using Position ID, please try after sometime"
        );
      })
      //Get user name from gpid
      .addCase(getUserNameFromGpid.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getUserNameFromGpid.fulfilled,
        (state, action: PayloadAction<any>) => {
          let payload = action.payload;

          //Update stepOne state
          if (payload.stepOne !== null) {
            state.formData.stepOne = {
              ...state.formData.stepOne,
              ...payload.stepOne,
            };
          }
          //Update stepFour state
          if (payload.stepFour !== null) {
            state.formData.stepFour = {
              ...state.formData.stepFour,
              ...payload.stepFour,
            };
          }
        }
      )
      .addCase(getUserNameFromGpid.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch user data from gpid api");
        //show error
        errorNotice("Error !!", "Cannot fetch user data using  GPID");
      })

      .addCase(getCurrentJobTitleFromEC.pending, (state) => {
        state.formLoaderEC = true;
      })
      .addCase(
        getCurrentJobTitleFromEC.fulfilled,
        (state, action: PayloadAction<any>) => {
          let payload = action.payload;
         
          if(state.language =='es' && payload?.nameEsMx)
            state.formData.stepOne.jobTitleId = payload.nameEsMx
          if(state.language =='en' && payload?.nameDefaultValue)
            state.formData.stepOne.jobTitleId = payload.nameDefaultValue
          //Loader
          state.formLoaderEC = false;
        }
      )
      //1. FOR POSITION DETAIL
      //1.1 master data fetch
      .addCase(getPositionDetailsMaster.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getPositionDetailsMaster.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.stepOne = {
            ...state.masterData.stepOne,
            ...action.payload.stepOne,
          };
          state.masterData.stepFour = {
            ...state.masterData.stepOne,
            ...action.payload.stepFour,
          };
          // update proposedCompensatedLevels array
          const proposedCompensatedLevelsArray: any =
            getProposedCompensatedLevelsArray(
              action.payload.stepOne.proposedLevels,
              state.formData.stepOne.proposedEvaluatedLevelId
            );
          proposedCompensatedLevelsArray.unshift({ value: 0, name: "N/A" });
          state.masterData.stepOne = {
            ...state.masterData.stepOne,
            proposedCompensatedLevels: proposedCompensatedLevelsArray,
          };
          state.formLoader = false;
        }
      )
      .addCase(getPositionDetailsMaster.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch position details master api");
      })
      //1.2 prepopulated data fetch
      .addCase(getPositionDetails.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getPositionDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
          let payload = action.payload;
          //levels set
          payload.currentEvaluatedLevelId = findLevelObjByName(
            payload.currentEvaluatedLevelId,
            state.masterData.stepOne.currentEvaluatedLevels
          ).value;
          payload.proposedLevelId = findLevelObjByName(
            payload.proposedLevelId,
            state.masterData.stepOne.currentEvaluatedLevels
          ).value;

          //Update state
          state.formData.stepOne = {
            ...state.formData.stepOne,
            ...payload,
          };
          state.formLoader = false;
        }
      )
      .addCase(getPositionDetails.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch position details api");
      })
      //1.3 subfunction data fetch
      .addCase(getSubFunctionsDropdown.pending, (state) => {
        // state.formLoader = true;
      })
      .addCase(
        getSubFunctionsDropdown.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.stepOne.subFunctions = action.payload;
          // state.formLoader = false;
        }
      )
      .addCase(getSubFunctionsDropdown.rejected, (state) => {
        // state.formLoader = false;
        console.log("Error is happen in fetch subFunctions details master api");
      })
      //1.4 submit form
      .addCase(submitPositionDetails.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(submitPositionDetails.fulfilled, (state, action) => {
        if (state.clickedOnDraft === false) {
          state.paqActiveStep += 1;
          state.paqProgress += 25;
        }
        state.formLoader = false;
        //**Send email only when "save as draft"
        if (
          state.oktaUserData !== null &&
          state.oktaUserRole !== "tr" &&
          state.clickedOnDraft && !state.isReviewAndSubmit
        ) {
          sendEmail({
            userName: state.oktaUserData["FirstName"],
            userEmail: state.oktaUserData["email"],
            requestId: state.requestId,
            step: state.paqActiveStep,
            language: state.language
          });
        }
        //show notice
        if (state.clickedOnDraft) {
          successNotice(
            `${getTranslated('toast.success') || 'Success'} !!`,
            getTranslated('toast.QuestionnaireSaved') || 'Job Evaluation Questionnaire saved successfully'
          );
        } else {
          successNotice(
            `${getTranslated('toast.success') || 'Success'}!!`,
            getTranslated('toast.positionDetailsSaved') || 'Position details have been saved successfully'
          );
        }
      })
      .addCase(submitPositionDetails.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in submit position details api");
        //Show error notice
        errorNotice(
          `${getTranslated('toast.ErrorSubmitPositionDetails') || 'Error in submit position details'}!!`,
          getTranslated('toast.somethingWentWrong') || 'Something went wrong, please try after sometime'
        );
      })
      //1.3 sectors data fetch
      .addCase(getSectorsDropdown.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getSectorsDropdown.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.stepOne.sectors = action.payload;
          state.formLoader = false;
        }
      )
      .addCase(getSectorsDropdown.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch sector details master api");
      })
      //1.5 Divisions data fetch
      .addCase(getDivisionsDropdown.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getDivisionsDropdown.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.stepOne.divisions = action.payload;
          state.formLoader = false;
        }
      )
      .addCase(getDivisionsDropdown.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch divition details master api");
      })
      //1.5 regions data fetch
      .addCase(getRegionsDropdown.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getRegionsDropdown.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.stepOne.regions = action.payload;
          state.formLoader = false;
        }
      )
      .addCase(getRegionsDropdown.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch regions details master api");
      })
      //1.6 buisness data fetch
      .addCase(getBusinessUnitsDropdown.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getBusinessUnitsDropdown.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.stepOne.businessUnits = action.payload;
          state.formLoader = false;
        }
      )
      .addCase(getBusinessUnitsDropdown.rejected, (state) => {
        state.formLoader = false;
        console.log(
          "Error is happen in fetch buisness unit details master api"
        );
      })
      //1.7 marketunit data fetch
      .addCase(getMarketUnitsDropdown.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getMarketUnitsDropdown.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.stepOne.marketUnits = action.payload;
          state.formLoader = false;
        }
      )
      .addCase(getMarketUnitsDropdown.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch marketUnits details master api");
      })
      //1.8 work locations data fetch
      .addCase(getWorkLocationsDropdown.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getWorkLocationsDropdown.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.stepOne.workLocations = action.payload;
          state.formLoader = false;
        }
      )
      .addCase(getWorkLocationsDropdown.rejected, (state) => {
        state.formLoader = false;
        console.log(
          "Error is happen in fetch workLocations details master api"
        );
      })
      //2. FOR ROLE CONTEXT
      //2.1 master data fetch
      .addCase(getRoleContextMaster.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getRoleContextMaster.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.stepTwo = {
            ...state.masterData.stepTwo,
            ...action.payload,
          };
          state.formLoader = false;
        }
      )
      .addCase(getRoleContextMaster.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch role context  master api");
      })
      //2.2 submit role context form
      .addCase(submitRoleContext.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(submitRoleContext.fulfilled, (state, action) => {
        if (state.clickedOnDraft === false) {
          state.paqActiveStep += 1;
          state.paqProgress += 25;
        }
        state.formLoader = false;
        //**Send email only when "save as draft"
        if (
          state.oktaUserData !== null &&
          state.oktaUserRole !== "tr" &&
          state.clickedOnDraft && !state.isReviewAndSubmit
        ) {
          sendEmail({
            userName: state.oktaUserData["FirstName"],
            userEmail: state.oktaUserData["email"],
            requestId: state.requestId,
            step: state.paqActiveStep,
            language: state.language
          });
        }
        //show notice
        if (state.clickedOnDraft) {
          successNotice(
            `${getTranslated('toast.success') || 'Success'}!!`,
            getTranslated('toast.QuestionnaireSaved') || 'Job Evaluation Questionnaire saved successfully'
          );
        } else {
          successNotice(
            `${getTranslated('toast.success') || 'Success'}!!`,
            getTranslated('toast.roleContextSaved') || 'Role Context has been successfully saved'
          );
        }
      })
      .addCase(submitRoleContext.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in submit role context api");
        //show error
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.somethingWrongRoleContext') || "Something went wrong to save 'Role Context', please try after sometime"
        );
      })
      //3. FOR KPI
      //3.1 master data fetch
      .addCase(getKpiMaster.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(getKpiMaster.fulfilled, (state, action: PayloadAction<any>) => {
        state.masterData.stepThree = {
          ...state.masterData.stepThree,
          ...action.payload,
        };
        state.formLoader = false;
      })
      .addCase(getKpiMaster.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch KPI  master api");
      })
      //3.2 submit kpi form
      .addCase(submitKpi.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(submitKpi.fulfilled, (state, action) => {
        if (state.clickedOnDraft === false) {
          state.paqActiveStep += 1;
          state.paqProgress += 25;
        }
        state.formLoader = false;
        //**Send email only when "save as draft"
        if (
          state.oktaUserData !== null &&
          state.oktaUserRole !== "tr" &&
          state.clickedOnDraft && !state.isReviewAndSubmit
        ) {
          sendEmail({
            userName: state.oktaUserData["FirstName"],
            userEmail: state.oktaUserData["email"],
            requestId: state.requestId,
            step: state.paqActiveStep,
            language: state.language
          });
        }
        //show notice
        if (state.clickedOnDraft) {
          successNotice(
            `${getTranslated('toast.success') || 'Success'}!!`,
            getTranslated('toast.QuestionnaireSaved') || 'Job Evaluation Questionnaire saved successfully'
          );
        } else {
          successNotice(
            `${getTranslated('toast.success') || 'Success'}!!`,
            getTranslated('toast.KPIsSaved') || 'KPIs has been successfully saved'
          );
        }
      })
      .addCase(submitKpi.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in submit role context api");
        //show error
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.somethingWrongKPIs') || 'Something went wrong to save KPIs data, please try after sometime'
        );
      })
      // Get functional kpis
      .addCase(getFunctionalKpis.pending, (state) => {
        // state.formLoader = true;
      })
      .addCase(
        getFunctionalKpis.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (action.payload.length != 0)
            state.masterData.stepThree.functionalKpis = action.payload;
        }
      )
      .addCase(getFunctionalKpis.rejected, (state) => {
        // state.formLoader = false;
        console.log("Error is happen in fetch Functional kpis api");
      })
      .addCase(getSubAreaCode.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getSubAreaCode.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.stepThree.subAreaCode = action.payload;
          state.formLoader = false;
        }
      )
      .addCase(getSubAreaCode.rejected, (state) => {
        state.masterData.stepThree.subAreaCode = [];
        state.formLoader = false;
        console.log("Error is happen in fetching sub-Area Code");
      })
      //3. FOR HIRERCHY
      //3.1 master data fetch
      .addCase(getHierarchyMaster.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getHierarchyMaster.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.masterData.stepFour = {
            ...state.masterData.stepFour,
            ...action.payload,
          };
          state.formLoader = false;
        }
      )
      .addCase(getHierarchyMaster.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in fetch hiarerchy master api");
      })
      //3.2 Hierarchy submit
      .addCase(submitPaq.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(submitPaq.fulfilled, (state, action) => {
        state.formLoader = false;
        //show notice
        if (state.paqActiveStep === 3) {
          state.caseId = action.payload.caseId;
          showCaseId(
            `${getTranslated('toast.success') || 'Success'}!!`,
            `${getTranslated('toast.ThankYouForSubmitting') || 'Thank you for submitting your request. The form is locked and will be processed by the total rewards team. A case has been created successfully in MyServices with a case ID'} : ${state.caseId}, ${getTranslated('toast.youCanTrack') || 'you can track status of the request using the case ID in future'}.`
          );
          state.pdfGenerated = false;
        }
      })
      .addCase(submitPaq.rejected, (state) => {
        state.formLoader = false;
        state.pdfGenerated = false;
        console.log("Error is happen in submit Hierarchy api");
        //show error
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.somethingWrongSubmitPAQ') || 'Something went wrong to submit PAQ, please try after sometime'
        );
      })
      // save step 4 as draft
      .addCase(submitHierarchyNoToast.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(submitHierarchyNoToast.fulfilled, (state, action) => {
        state.formLoader = false;
      })
      .addCase(submitHierarchyNoToast.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in submit Hierarchy api");
      })
      .addCase(submitHierarchy.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(submitHierarchy.fulfilled, (state, action) => {
        state.formLoader = false;
        if (state.clickedOnDraft === false) {
          state.paqActiveStep += 1;
        }
        //**Send email only when "save as draft"
        if (
          state.oktaUserData !== null &&
          state.oktaUserRole !== "tr" &&
          state.clickedOnDraft && !state.isReviewAndSubmit
        ) {
          sendEmail({
            userName: state.oktaUserData["FirstName"],
            userEmail: state.oktaUserData["email"],
            requestId: state.requestId,
            step: state.paqActiveStep,
            language: state.language
          });
        }
        if (state.clickedOnDraft) {
          successNotice(
            `${getTranslated('toast.success') || 'Success'}!!`,
            getTranslated('toast.QuestionnaireSaved') || 'Job Evaluation Questionnaire saved successfully'
          );
        } else {
          successNotice(
            `${getTranslated('toast.success') || 'Success'}!!`,
            getTranslated('toast.HierarchySaved') || 'Hierarchy has been successfully saved'
          );
        }
      })
      .addCase(submitHierarchy.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in submit Hierarchy api");
      })
      //3.3 upload current org chert
      .addCase(submitCurrentOrganizationChart.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(submitCurrentOrganizationChart.fulfilled, (state, action) => {
        state.formLoader = false;
        state.formData.stepFour.currentStateOrgChartFileName =
          StringForFileUpload;
        //show notice
        successNotice(
          `${getTranslated('toast.success') || 'Success'}!!`,
          getTranslated('toast.currentChartUploaded') || 'Current Organization Chart Uploaded Successfully'
        );
      })
      .addCase(submitCurrentOrganizationChart.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in submit current org chert api");
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.unableUploadCurrentChart') || 'Unable to Upload Current Organization Chart, please try again'
        );
      })
      //3.4 upload future org chert
      .addCase(submitFutureOrganizationChart.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(submitFutureOrganizationChart.fulfilled, (state, action) => {
        state.formLoader = false;
        state.formData.stepFour.futureStateOrgChartFileName =
          StringForFileUpload;
        //show notice
        successNotice(
          `${getTranslated('toast.success') || 'Success'}!!`,
          getTranslated('toast.futureChartUploaded') || 'Future Organization Chart Uploaded Successfully'
        );
      })
      .addCase(submitFutureOrganizationChart.rejected, (state) => {
        state.formLoader = false;
        console.log("Error is happen in submit future org chert api");
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.unableUploadFutureChart') || 'Unable to Upload Future Organization Chart, please try again'
        );
      })
      // change form status Draft/Submitted
      .addCase(updateFormLockStatus.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(updateFormLockStatus.fulfilled, (state, action) => {
        state.formLoader = false;
        let formLockedOld = state.formLocked;
        state.formLocked = !formLockedOld;
      })
      .addCase(updateFormLockStatus.rejected, (state) => {
        state.formLoader = false;
        //Show error notice
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.somethingWentWrong') || 'Something went wrong, please try after sometime'
        );
      })
      // get Tr Additional Details
      .addCase(getTrAdditionalDetails.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getTrAdditionalDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.formData.trEvaluationStep = {
            ...state.formData.trEvaluationStep,
            requestorName: action.payload.requestorName,
            snowCaseId: action.payload.snowCaseId,
          };
          if (action.payload.evaluatedLevel !== null) {
            state.formData.trEvaluationStep = {
              ...state.formData.trEvaluationStep,
              evaluatedLevelId: action.payload.evaluatedLevel,
            };
          }
          if (action.payload.futureJobCode !== null) {
            state.formData.trEvaluationStep = {
              ...state.formData.trEvaluationStep,
              futureJobCode: action.payload.futureJobCode,
            };
          }
          state.showLastPage = true;
          state.formLoader = false;
        }
      )
      .addCase(getTrAdditionalDetails.rejected, (state) => {
        state.formLoader = false;
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          `${getTranslated('toast.errorFetchingTRDetails') || 'Error while fetching TR details'} !`
        );
        console.log("Error while fetching TR details !");
      })
      // fetch future job code
      .addCase(getFutureJobCode.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(
        getFutureJobCode.fulfilled,
        (state, action: PayloadAction<any>) => {
          if (
            action.payload.futureJobCode !== null &&
            action.payload.futureJobCode !== "" &&
            action.payload.futureJobCode !== undefined
          ) {
            state.futureJobCodeReadonly = true;
          } else {
            state.futureJobCodeReadonly = false;
          }
          state.formData.trEvaluationStep = {
            ...state.formData.trEvaluationStep,
            futureJobCode:
              action.payload.futureJobCode === null
                ? ""
                : action.payload.futureJobCode,
          };
          state.formLoader = false;
        }
      )
      .addCase(getFutureJobCode.rejected, (state) => {
        state.formLoader = false;
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          `${getTranslated('toast.errorFetchingFutureJobCode') || 'Error while fetching Future Job Code'} !`
        );
        console.log("Error while fetching Future Job Code !");
      })
      // submit last page (TR)
      .addCase(createTrHr.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(createTrHr.fulfilled, (state, action) => {
        state.trFormSubmitted = true;
        state.formLoader = false;
        successNotice(
          `${getTranslated('toast.success') || 'Success'}!!`,
          getTranslated('toast.formSubmitted') || 'Form submitted successfully'
        );
      })
      .addCase(createTrHr.rejected, (state) => {
        state.formLoader = false;
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          getTranslated('toast.somethingWentWrong') || 'Something went wrong, please try after sometime'
        );
      })
      // generate pdf response
      .addCase(generatePaqPdf.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(generatePaqPdf.fulfilled, (state, action) => {
        state.formLoader = false;
        state.pdfGenerated = true;
      })
      .addCase(generatePaqPdf.rejected, (state) => {
        state.formLoader = false;
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          `${getTranslated('toast.formSubmissioFailed') || 'Form submission failed, please try after sometime'} !`
        );
      })
      // get Form Status
      .addCase(getFormStatus.pending, (state) => {
        state.formLoader = true;
      })
      .addCase(getFormStatus.fulfilled, (state, action) => {
        state.status = action.payload.status;
        state.formStatusFetched = true;
        state.caseIdFetched = action.payload.caseId;
        if (action.payload.status === "Submitted" || action.payload.status === "Completed") {
          state.formLocked = true;
        }
        if(action.payload.language){
          state.language = action.payload.language
        }
      })
      .addCase(getFormStatus.rejected, (state) => {
        state.formLoader = false;
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          `${getTranslated('toast.cannotFetchFormStatus') || 'Cannot fetch form status, please try after sometime'} !`
        );
      })
      // get demo api response
      .addCase(getQueryParams.pending, (state) => {
        // state.formLoader = true;
      })
      .addCase(getQueryParams.fulfilled, (state, action) => {
        if (action.payload) {
          state.gpId = action.payload.incumbentGPID;
          state.formData.stepOne.purposeOfRequestId =
            action.payload.purposeOfRequest;
          state.formData.stepOne.proposedEvaluatedLevelId =
            action.payload.proposedLevel;
          state.formData.stepOne.proposedCompensatedLevelId =
            action.payload.proposedLevel;
          state.formData.stepOne.functionCode = action.payload.function;
          state.formData.stepOne.subFunctionCode = action.payload.subFunction;
          state.requestOfForm = action.payload.requestOfForm;
          if(action.payload.language){
            state.language = action.payload.language
          }
        }
        state.authorize = true;
        state.authorizationFailed = false;
      })
      .addCase(getQueryParams.rejected, (state) => {
        state.authorize = false;
        state.authorizationFailed = true;
        errorNotice(
          `${getTranslated('toast.error') || 'Error'}!!`,
          `${getTranslated('alert.noAccess') || 'User do not have access to the form'} !`
        );
      });
  },
});

export const {
  updateClickedOnDraft,
  updateIsReviewAndSubmit,
  updateExternalStakeHoldersName,
  nextStep,
  prevStep,
  goToLastPage,
  goFromLandingPageToForm,
  goToLandingPage,
  viewForm,
  updateReqId,
  updateUserId,
  updateOktaUserData,
  updateOktaUserRole,
  updatePurposeOfRequestId,
  updateProposedEvaluatedLevelId,
  updateFunctionCode,
  updateSubFunctionCode,
  updateStepOneMasterData,
  updateStepOneFormData,
  updateStepTwoFormData,
  updateStepThreeFormData,
  updateStepFourFormData,
  updateTrEvaluationStepFormData,
  updateFormLocked,
  updateAccessToLastPage,
  updateAuthorize,
  updatePaqActiveStep,
  skipLandingPage,
  updateStepNumber,
  updateLanguage
} = paqReEvaluateSlice.actions;

export default paqReEvaluateSlice.reducer;
