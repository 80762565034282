

const validate = (value: any) => {
    if (value !== null && value !== "" && value !== 0) {
      return true;
    } else {
      return false;
    }
  };
  const validateNumberFields = (value: any) => {
    if (value !== null && value !== "") {
      return true;
    } else {
      return false;
    }
  };
  export const positionDetailsValidate = (payload: any) => {
    let requiredProps = [
      "purposeOfRequestId",
      "incumbentName",
      "incumbentGPID",
      "currentPositionId",
      "currentEvaluatedLevelId",
      "proposedLevelId",
      "managerGPID",
      "effectiveDate",
      "functionCode",
      "subFunctionCode",
      "proposedJobTitleId",
      "countryCode",
      "sectorCode",
      "divisionCode",
      "regionCode",
      "businessUnitCode",
      "marketUnitCode",
      "workLocationCode",
    ];
    let result = true;
    requiredProps.forEach(function (item, index) {
      if (!validate(payload[item])) {
        result = false;
      }
    });
  
    return result;
  };
  
  export const roleContextValidate = (payload: any, fmMasterData: any, functionValue?:any) => { 
    let requiredProps = [
      "rolePurpose",
      "businessContextForRoleReevaluationId",
      "otherDescription",
      "currentStateOrgChartFileName",
      "futureStateOrgChartFileName"
    ];

    let requiredNumberProps = [
      "numberOfDirectReports"
    ]

    if (functionValue == 'JF009') {
      const franchiseFields = [
        "organisationLayerId",
        "totalBeverageVolume",
        "nopbt",
      ];
    
      // Add the elements from franchiseFields to requiredProps
      requiredProps.push(...franchiseFields);      
    }    
    
    let result = true;
    requiredProps.forEach(function (item, index) {
      if (!validate(payload[item])) {
        result = false;
      }
    }); 
    requiredNumberProps.forEach(function (item, index) {
      if (!validateNumberFields(payload[item])) {
        result = false;
      }
    });
    return result;
  };
  