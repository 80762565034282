import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  button {
    margin-left: 4px;
    margin-right: 8px;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media (max-width: 768px) {
    display: block;
    button {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
`;
