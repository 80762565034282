import styled from "styled-components";

export const FormControlFlex = styled.div`
  display: flex;
  flex-direction: row;
  margin: 15x 0;
  margin-top: 20px;

  .inputSet {
    margin: 0;
  }

  & > div {
    flex: 0.5;
  }
  & > div:first-child {
    margin-right: 10px;
    display: flex;
  }
  & > div:first-child svg {
    margin-left: 10px;
  }

  & .lebel span {
    font-size: 16px;
  }

  @media (max-width: 580px) {
    flex-direction: column;
    margin-bottom: 20px;
    & > div {
      flex: 1;
    }
    & > div:first-child {
      margin-bottom: 10px;
      margin-right: 0;
    }
  }
`;
