import * as React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { FormControlFlex } from "../styled";
import { ToolTip } from "./ToolTip";

interface IFormNumber {
  updateState: Function;
  data: any;
}

const FormNumber = ({ updateState, data }: IFormNumber) => {
  /*
  props .data

  {
    label: "Purpose of request",
    value: "lore ipsum",
    name:'purpose_of_request',
  }
  */
  //local State for error
  const [validationError, setValidationError] = React.useState(false);
  const handleChange = (key: any, event: any) => {
    updateState({
      [key]: event.target.value.replace(/[^0-9]/, ""),
    });
  };

  React.useEffect(() => {
    if (data.required != undefined && data.required === true) {
      if (
        data.value !== undefined &&
        data.value !== null &&
        data.value !== ""
      ) {
        setValidationError(false);
      } else {
        setValidationError(true);
      }
    }
  }, [data]);

  return (
    <>
      <FormControlFlex>
        <div className="label">
          <span>
            {data.label}
            {data.required != undefined && data.required === true && (
              <span style={{ color: "white", marginLeft: 5 }}>*</span>
            )}
          </span>
          {data.toolTipText != undefined ? (
            <ToolTip title={data.toolTipText} />
          ) : (
            ""
          )}
        </div>
        <div>
          <FormControl fullWidth>
            <TextField
              type="number"
              disabled={
                data.readOnly != undefined && data.readOnly === true
                  ? true
                  : false
              }
              error={validationError}
              size="small"
              id="outlined-basic"
              label=""
              variant="outlined"
              value={data.value}
              onInput={(e) => handleChange(data.name, e)}
              inputProps={{ maxLength: 256 }}
            />
          </FormControl>
        </div>
      </FormControlFlex>
    </>
  );
};
export default FormNumber;
