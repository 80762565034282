import { Grid } from "@mui/material"
import FormSelect from "../../../app/form-inputs/FormSelect"
import FormText from "../../../app/form-inputs/FormText"
import { getTranslated } from "../../../app/functions/getTranslatedLevel"


export const FmFranchise=({updateStaticStateData,formStaticData,masterData,formLocked}:any)=>{
    return(<Grid container>
        <Grid item xs={12} md={6} p={3}>
            
            <FormSelect
                data={{
                label:
                getTranslated("step2.organisationLayerId") || "Organization Layer (PEP CEO = 1)",
                value: formStaticData.organisationLayerId,
                options: masterData.orgLayers,
                name: "organisationLayerId",
                readOnly: formLocked,
                required: true,
            }}
            updateState={updateStaticStateData}
            />
            

        </Grid>
        <Grid item xs={12} md={6} p={3}>
            <FormText
              data={{
                label: getTranslated("step2.totalBeverageVolume") || "Total Beverage Volume (8oz)",
                value: formStaticData.totalBeverageVolume,
                name: "totalBeverageVolume",
                required: true,
                readOnly: formLocked,
              }}
              updateState={updateStaticStateData}
              isNumericField={true}
            />
            <FormText
              data={{
                label: getTranslated("step2.nopbt") || "NOPBT ($MM)",
                value: formStaticData.nopbt,
                name: "nopbt",
                required: true,
                readOnly: formLocked,
                
              }}
              updateState={updateStaticStateData}
              isCurrencyField={true}
            />
            <FormText
              data={{
                label: getTranslated("step2.systemNetRevenue") || "System Net Revenue ($MM)",
                value: formStaticData.systemNetRevenue,
                name: "systemNetRevenue",
                
                readOnly: formLocked,
                
              }}
              updateState={updateStaticStateData}
              isCurrencyField={true}
            />
        </Grid>
      </Grid>)
}