import styled from "styled-components";
interface ISpan {
  size: string;
}

const getSize = (size: string) => {
  switch (size) {
    case "large":
      return "50px";

    case "medium":
      return "20px";

    case "small":
      return "16px";

    default:
      return "16px";
  }
};
export const Text = styled.span<ISpan>`
  font-size: ${({ size }) => getSize(size)};
`;
