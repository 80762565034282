import * as React from "react";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import CircularProgress from "@mui/material/CircularProgress";
import LinearProgressWithLabel from "../../app/progress-bar/LinearProgressWithLabel";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  updateClickedOnDraft,
  nextStep,
  prevStep,
  goToLastPage,
  goFromLandingPageToForm,
  viewForm,
  updateReqId,
  updateUserId,
  getDraftedData,
  getPreDefinedData,
  submitPositionDetails,
  submitRoleContext,
  submitKpi,
  submitHierarchy,
  generatePaqPdf,
  updateFormLockStatus,
  updateAccessToLastPage,
  submitHierarchyNoToast,
  submitPaq,
  getTrAdditionalDetails,
  createTrHr,
  getManagerName,
  getFormStatus,
  updateAuthorize,
  getQueryParams,
  updatePaqActiveStep,
  updateOktaUserData,
  updateOktaUserRole,
  goToLandingPage,
  getPositionDetailsMaster,
  skipLandingPage,
  updateStepNumber,
  updateLanguage,
  updateIsReviewAndSubmit,
} from "./paqSlice";
import PaqLandingPage from "./PaqLandingPage";
import PaqStepOne from "./steps/step-one/PaqStepOne";
import PaqStepTwo from "./steps/step-two/PaqStepTwo";
import PaqStepFour from "./steps/step-four/PaqStepFour";
import { useSearchParams } from "react-router-dom";
import {
  ButtonPrimary,
  ButtonSecondary,
  ButtonsContainer,
} from "../../app/styled";
import { useAppDispatch } from "../../app/hooks";
import PaqStepThree from "./steps/step-three/PaqStepThree";
import {
  positionDetailsValidate,
  kpisValidate,
  roleContextValidate,
  heirarchyValidate,
} from "./Validation";
import { getPaqPdfPayload } from "./getPaqPdfPayload";
import { FINAL_STEP_MESSAGE } from "../../app/constants";
import TrEvaluationPage from "./steps/trEvaluationPage/TrEvaluationPage";
import Button from "@mui/material/Button";
import { useOktaAuth } from "@okta/okta-react";
import {
  deleteFileNames,
  validateTrHr,
} from "../../app/functions/CommonFunctions";
import { Divider } from "@mui/material";
import { EditButton } from "../../app/form-inputs/EditButton";
import Header from "../header/Header";
import { getTranslated } from "../../app/functions/getTranslatedLevel";
import PaqSubmitDialog from "../../app/dialog/PaqSubmitDialog";

const steps = ["POSITION DETAILS", "ROLE CONTEXT", "KPIs", "HIERARCHY"];

const Paq = () => {
  const { authState } = useOktaAuth();
  const oktaClaims: any = authState?.idToken?.claims
    ? authState.idToken.claims
    : null;
  const navigate = useNavigate();
  const appDispatch = useAppDispatch();
  const [queryParams,setQueryParams] = useSearchParams();

//handle Modal variables and methods - START
const [isError, setIsError] = React.useState<boolean>(false);
const [isConfirmModal, setConfirmModal] = React.useState<boolean>(false);
const [errMsg, setErrMsg] = React.useState<string>("");
const handleModalOpen = (msg: string) => {
  setIsError(true)
  setErrMsg(msg);
}
const handleModalClose = () => {
  setErrMsg("");
  setIsError(false)
  setConfirmModal(false);
}
const handleConfirmModalOpen = (msg: string) => {
  setConfirmModal(true)
  setErrMsg(msg);
}
//handle Modal variables and methods - END

  //get data from url query params
  appDispatch(updateUserId(oktaClaims?.gpid));
  appDispatch(updateOktaUserData(oktaClaims));
  let requestId = queryParams.get("requestId");
  let showDraftedValue = queryParams.get("showDraftedValue");
  let showSummary = queryParams.get("showSummary");
  // fetching query params form Manager not TR
  let userRole: any =
    oktaClaims?.pepapphrjepcroles[0] == "Manager"
      ? "manager"
      : oktaClaims?.pepapphrjepcroles[0] == "TRUsers"
      ? "tr"
      : "invalid";
  appDispatch(updateOktaUserRole(userRole));

  const showLandingPage = useSelector(
    (state: any) => state.paq.showLandingPage
  );

  React.useEffect(() => {
    if (userRole === "tr") {
      appDispatch(updateAuthorize(true));
    } else if (userRole === "manager") {
      let payload = {
        requestId: requestId,
        userGPID: oktaClaims?.gpid,
      };
      appDispatch(getQueryParams(payload));
    }
  }, [requestId, oktaClaims?.gpid, userRole, appDispatch]);

  const paqState = useSelector((state: any) => state.paq);
  const paqActiveStep = useSelector((state: any) => state.paq.paqActiveStep);
  const progress = useSelector((state: any) => state.paq.paqProgress);
  const formLoaderPaq = useSelector((state: any) => state.paq.formLoader);
  const formLoaderEC = useSelector((state: any) => state.paq.formLoaderEC);
  const formLoader = formLoaderPaq || formLoaderEC
  const hasAccessToLastPage = useSelector(
    (state: any) => state.paq.hasAccessToLastPage
  );
  const showLastPage = useSelector((state: any) => state.paq.showLastPage);
  const formLocked = useSelector((state: any) => state.paq.formLocked);

  React.useEffect(() => {
    if (paqActiveStep == 4) {
      appDispatch(updatePaqActiveStep(3));
      let queryString = window.location.search;
      navigate(`/paq${queryString}&showSummary=true`);
    }
  }, [paqActiveStep]);

  React.useEffect(()=>{
    const param = queryParams.get('step');
    if (param) {
      // skipLandingPage
      appDispatch(skipLandingPage());
      // update active step
      appDispatch(updateStepNumber(Number(param)))
      // 👇️ delete each query param
      queryParams.delete('step');
      // 👇️ update state after
      setQueryParams(queryParams);
    }
  },[queryParams])

  //Master Data
  const paqMasterData = useSelector((state: any) => state.paq.masterData);

  //Form datas
  const paqFormData = useSelector((state: any) => state.paq.formData);
  //1. Position details form data
  const positonDetailsFormData = useSelector(
    (state: any) => state.paq.formData.stepOne
  );
  //2. role context
  const roleContextsFormData = useSelector(
    (state: any) => state.paq.formData.stepTwo
  );
  //3. kpi form data
  const kpisFormData = useSelector(
    (state: any) => state.paq.formData.stepThree
  );
  //4. Hierarchy form data
  const hierarchyFormData = useSelector(
    (state: any) => state.paq.formData.stepFour
  );
  //5. trEvaluationStep form data
  const trEvaluationFormData = useSelector(
    (state: any) => state.paq.formData.trEvaluationStep
  );

  const stepForm = (param: number) => {
    switch (param) {
      case 0:
        return <PaqStepOne updateFormData={updateFormData} />;
      case 1:
        return <PaqStepTwo updateFormData={updateFormData} />;
      case 2:
        return <PaqStepThree updateFormData={updateFormData} />;
      case 3:
        return <PaqStepFour updateFormData={updateFormData} />;
      default:
        return <PaqStepOne updateFormData={updateFormData} />;
    }
  };

  const stepNameAndEditButton = (step: number) => {
    return <EditButton stepName={getTranslated(`steps.${step}`) || steps[step]} onEdit={() => onEdit(step)} />;
  };

  //Main dispatch
  const updateFormData = (params: any) => {
    appDispatch(params);
  };
  const handleNextAndSubmit = (saveDraft = false, isReviewAndSubmit = false) => {
    let moveToNextStep = false;
    appDispatch(updateClickedOnDraft(saveDraft));
    appDispatch(updateIsReviewAndSubmit(isReviewAndSubmit));
    //Common value
    let commonPayload = {
      requestId: paqState.requestId,
      user: paqState.userId,
    };
    //form submit with draft state and
    if (paqActiveStep === 0) {
      const { submittedDate, ...rest } = positonDetailsFormData;
      let insertPayload = { ...commonPayload, status: "Draft", ...rest};
      if (saveDraft || positionDetailsValidate(insertPayload)) {
        appDispatch(submitPositionDetails(insertPayload));
        if (!saveDraft) moveToNextStep = true;
      } else {
        handleModalOpen(getTranslated("alert.fillRequiredFields") || "Please fill required fields");
      }
    }
    if (paqActiveStep === 1) {
      let insertPayload = { ...commonPayload, ...roleContextsFormData };
      if (saveDraft || roleContextValidate(insertPayload, paqMasterData)) {
        appDispatch(submitRoleContext(insertPayload));
        if (!saveDraft) moveToNextStep = true;
      } else {
        handleModalOpen(getTranslated("alert.fillRequiredFields") || "Please fill required fields");
      }
    }
    if (paqActiveStep === 2) {
      let insertPayload = {
        ...kpisFormData,
        ...commonPayload,
      };
      if (saveDraft || kpisValidate(insertPayload)) {
        appDispatch(submitKpi(insertPayload));
        if (!saveDraft) moveToNextStep = true;
      } else {
        handleModalOpen((getTranslated("alert.fillRequiredFields") || "Please fill required fields")+" !!");
      }
    }
    if (paqActiveStep === 3) {
      let insertPayload = {
        ...commonPayload,
        status: "Draft",
        ...hierarchyFormData,
      };

      if (saveDraft || heirarchyValidate(insertPayload)) {
        appDispatch(submitHierarchy(deleteFileNames(insertPayload)));
        if (!saveDraft) moveToNextStep = true;
      } else {
        handleModalOpen(getTranslated("alert.fillRequiredFields") || "Please fill required fields");
      }
      //Main form fields submit
    }
    //Submit hiererchey details when save as draft from another step
    if (paqActiveStep !== 3) {
      let insertPayload = {
        ...commonPayload,
        status: "Draft",
        ...hierarchyFormData,
      };
      appDispatch(submitHierarchyNoToast(deleteFileNames(insertPayload)));
    }
    return moveToNextStep;
  };

  const handleLandingPageToForm = () => {
    appDispatch(goFromLandingPageToForm());
  };

  React.useEffect(() => {
    if (paqState.authorize) {
      if (paqState.pdfGenerated) {
        let insertPayload = {
          requestId: paqState.requestId,
          status: "Draft",
          user: paqState.userId,
        };
        insertPayload.status = "Submitted";
        let payload = { ...insertPayload, ...hierarchyFormData };
        appDispatch(submitPaq(deleteFileNames(payload)));
      }
    }
  }, [paqState.pdfGenerated, paqState.authorize]);

  const handleSubmit = () => {
      const formDetails = getPaqPdfPayload(paqFormData, paqMasterData);
      const payload = {
        requestId,
        user: paqState.userId,
        formType: "Position Analysis Questionnaire(PAQ)",
        formDetails,
      };
      appDispatch(generatePaqPdf(payload));
  };

  const handlePrev = () => {
    if (paqActiveStep > 0) {
      appDispatch(prevStep());
    }
  };

  const handleLastNext = () => {
    let trAdditionalDetailsPayload = {
      functionCode: paqState.formData.stepOne.functionCode,
      subFunctionCode: paqState.formData.stepOne.subFunctionCode,
      requestId: paqState.requestId,
    };
    appDispatch(getTrAdditionalDetails(trAdditionalDetailsPayload));
  };

  const handleViewForm = () => {
    appDispatch(viewForm());
    appDispatch(goToLastPage(!showLastPage));
  };

  const handleFormLocked = () => {
    let newStatus = formLocked === true ? "Draft" : "Submitted";
    let payload = {
      requestId: paqState.requestId,
      status: newStatus,
      user: oktaClaims?.gpid,
    };
    appDispatch(updateFormLockStatus(payload));
  };

  const handleNextForTR = () => {
    appDispatch(nextStep());
  };

  const handleSubmitLastPage = () => {
    let payload = {
      evaluatedBy: trEvaluationFormData.evaluatedBy,
      evaluatedLevel: trEvaluationFormData.evaluatedLevelId,
      evaluationMethod: trEvaluationFormData.evaluationMethod,
      futureJobCode: trEvaluationFormData.futureJobCode,
      requestorName: trEvaluationFormData.requestorName,
      snowCaseId: trEvaluationFormData.snowCaseId,
      requestId: paqState.requestId,
      user: oktaClaims?.gpid,
    };
    //if (validateTrHr(payload)) {
      appDispatch(createTrHr(payload));
    //} else {
    //  handleModalOpen(getTranslated("alert.fillRequiredFields") || "Please fill required fields");
    //}
  };

  const handlePreviewAndSubmit = () => {
    handleNextAndSubmit(true, true);
    appDispatch(updatePaqActiveStep(4));
  };

  const onEdit = (stepNumber: number) => {
    appDispatch(updatePaqActiveStep(stepNumber));
    let queryString = window.location.search;
    navigate(`/paq${queryString.replace("&showSummary=true", "")}`);
  };

  //use effect
  React.useEffect(() => {
    if (paqState.authorize) {
      // Update data from query params
      appDispatch(updateReqId(requestId));
      //check form status
      appDispatch(getFormStatus(requestId));

      if (showDraftedValue !== null && showDraftedValue === "yes") {
        //get drafted value
        appDispatch(getDraftedData(requestId));
      } 
      else {
        // get pre defined data
        let payload = {
          incumbentGPID: paqState.formData.stepOne.incumbentGPID,
          requestId: requestId,
        };
        updateFormData(getPreDefinedData(payload));
      }
      updateFormData(getPositionDetailsMaster());
      //------------- to be DELETED-----------------------------
      if (userRole !== null && userRole === "tr") {
        appDispatch(updateAccessToLastPage(true));
      }
      // ---------------------------------------------------------
    }
  }, [paqState.authorize]);

  //Get manager name from manager gpid
  React.useEffect(() => {
    if (paqState.authorize) {
      if (
        !(
          showDraftedValue == "yes" &&
          paqState.formData.stepFour.managerGPID == null
        )
      ) {
        if (paqState.formData.stepFour.managerGPID != null) {
          appDispatch(getManagerName(paqState.formData.stepFour.managerGPID));
        }
      }
    }
  }, [paqState.formData.stepFour.managerGPID, paqState.authorize]);

  const handleExit = () => {
    appDispatch(goToLandingPage());
    let queryString = window.location.search;
    navigate(`/paq${queryString.replace("&showSummary=true", "")}`);
    window.location.reload();
  };

  React.useEffect(() => {
    if (paqState.status === "Draft" && showDraftedValue !== "yes") {
      // redirect to drafted page
      navigate(`/paq?requestId=${requestId}&showDraftedValue=yes`);
      window.location.reload();
    }
  });

  return <React.Fragment>
  <Header updateLanguage = {updateLanguage} state={paqState}/>
  {isError && <PaqSubmitDialog customMsg={errMsg} handleModalClose={handleModalClose} />}
  {isConfirmModal && <PaqSubmitDialog customMsg={errMsg} handleModalClose={handleModalClose} handleModalSubmit={handleSubmit} isConfirm={true} />}
  {!paqState.authorizationFailed && !paqState.authorize ? (
    <div>{getTranslated("alert.checkingAccess") || "Checking access"} ...</div>
  ) : paqState.authorizationFailed ? (
    <div>{getTranslated("alert.noAccess") || "User do not have access to this form"} !</div>
  ) : userRole === "invalid" ? (
    <div>{getTranslated("alert.unableToAccess") || "Unable to access this form"} !</div>
  ) : paqState.formStatusFetched === true ? (
    (paqState.status === "Submitted" || paqState.status === "Completed" || paqState.caseId) &&
    userRole !== "tr" ? (
      <div style={{ fontFamily: "initial", textAlign: "center" }}>
        {paqState.status === "Completed" ? (
          <h3>{getTranslated("alert.alreadyCompleted") || "Form is already completed."}</h3>
        ) : (
          <h3>{getTranslated("alert.formSubmitted") || "Form is successfully submitted."}</h3>
        )}
        <h3>
          {(getTranslated("alert.caseId") || "Case-Id")+" : "}
          {paqState.status === "Submitted" ||
          paqState.status === "Completed" ? (
            <span
              dangerouslySetInnerHTML={{ __html: paqState.caseIdFetched }}
            ></span>
          ) : (
            <span dangerouslySetInnerHTML={{ __html: paqState.caseId }}></span>
          )}
        </h3>
      </div>
    ) : userRole == "tr" && paqState.trFormSubmitted ? (
      <div style={{ fontFamily: "initial", textAlign: "center" }}>
        {/* "TR Form submit success" */}
        <h3>{getTranslated("toast.formSubmitted") || "Form submitted successfully"}</h3>
      </div>
    ) : (
      <div className="page-wrapper">
        <Container maxWidth="lg" className="container-padding-vertical">
          <div className="form-wrapper">
            {showLandingPage ? (
              <PaqLandingPage handleNextClick={handleLandingPageToForm} />
            ) : showLastPage ? (
              <Grid container spacing={2}>
                {/*--FORM CONTENT---*/}
                <Grid item xs={12} md={12} mt={2} className="form-conatiner">
                  {formLoader && (
                    <div className="loader-wrapper">
                      <CircularProgress />
                    </div>
                  )}
                  <TrEvaluationPage updateFormData={updateFormData} />
                </Grid>
                {/*--FORM BOTTOM---*/}
                <Grid item xs={12} md={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={12} lg={12}>
                      <ButtonsContainer style={{ justifyContent: "right" }}>
                        <ButtonPrimary onClick={() => handleViewForm()}>
                          {getTranslated("buttons.viewForm") || "View Form"}
                        </ButtonPrimary>
                        {formLocked ? (
                          <ButtonPrimary onClick={() => handleFormLocked()}>
                            {getTranslated("buttons.unlockForm") || "Unlock Form"}
                          </ButtonPrimary>
                        ) : (
                          <Button variant="contained" disabled>
                            {getTranslated("buttons.unlockForm") || "Unlock Form"}
                          </Button>
                        )}
                        {!formLocked ? (
                          <ButtonPrimary onClick={() => handleFormLocked()}>
                            {getTranslated("buttons.lockForm") || "Lock Form"}
                          </ButtonPrimary>
                        ) : (
                          <Button variant="contained" disabled>
                            {getTranslated("buttons.lockForm") || "Lock Form"}
                          </Button>
                        )}
                        <ButtonPrimary
                          style={{ marginRight: 0 }}
                          onClick={() => handleSubmitLastPage()}
                        >
                          {/* Save Form */}
                          {getTranslated("buttons.submit") || "Submit"}
                        </ButtonPrimary>
                      </ButtonsContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ) : showSummary ? (
              <div>
                {getTranslated("others.reviewQuestionnaire") || "Please review questionnaire responses before submitting."}
                <Divider style={{ marginTop: "0.5em" }} />
                {stepNameAndEditButton(0)}
                <PaqStepOne
                  updateFormData={updateFormData}
                  disabled={showSummary === "true"}
                />
                <Divider style={{ marginTop: "1em" }} />
                {stepNameAndEditButton(1)}
                <PaqStepTwo
                  updateFormData={updateFormData}
                  disabled={showSummary === "true"}
                />
                <Divider style={{ marginTop: "1em" }} />
                {stepNameAndEditButton(2)}
                <PaqStepThree
                  updateFormData={updateFormData}
                  disabled={showSummary === "true"}
                />
                <Divider style={{ marginTop: "1em" }} />
                {stepNameAndEditButton(3)}
                <PaqStepFour
                  updateFormData={updateFormData}
                  disabled={showSummary === "true"}
                />
                <Divider style={{ marginTop: "1em" }} />
                <Grid item xs={12} md={12} mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <LinearProgressWithLabel value={100} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ButtonsContainer style={{ justifyContent: "right" }}>
                        <ButtonSecondary
                          onClick={() => handleExit()}
                          disabled={formLoader ? true : false}
                          className={formLoader && "disabledButton"}
                        >
                          {getTranslated("buttons.exit") || "Exit"}
                        </ButtonSecondary>
                        <ButtonPrimary
                          onClick={() => handleConfirmModalOpen(getTranslated("alert.finalStepMessage") || FINAL_STEP_MESSAGE)}
                          style={{ marginRight: 0 }}
                          disabled={formLoader ? true : false}
                          className={formLoader && "disabledButton"}
                        >
                          {formLoader ? (getTranslated("buttons.submitting") || "Submitting")+"..." : getTranslated("buttons.submit") || "Submit"}
                        </ButtonPrimary>
                        {formLoader && (
                          <CircularProgress
                            size={20}
                            style={{ marginLeft: 10 }}
                          />
                        )}
                      </ButtonsContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            ) : (
              <Grid container spacing={2}>
                {/*--STEPER START---*/}
                <Grid item xs={12} md={12} mt={2} mb={1}>
                  <Stepper activeStep={paqActiveStep}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps} style={{ fontSize: 18 }}>
                          {/* {t(`steps.${index}`)} */}
                          {getTranslated(`steps.${index}`)}
                          </StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                </Grid>
                {/*--FORM CONTENT---*/}
                <Grid item xs={12} md={12} mt={2} className="form-conatiner">
                  {formLoader && (
                    <div className="loader-wrapper">
                      <CircularProgress />
                    </div>
                  )}

                  {stepForm(paqActiveStep)}
                </Grid>
                {/*--FORM BOTTOM---*/}
                <Grid item xs={12} md={12} mt={5}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <LinearProgressWithLabel value={progress} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ButtonsContainer style={{ justifyContent: "right" }}>
                        {paqState.fromSummary && (
                          <ButtonSecondary
                            onClick={() => handlePreviewAndSubmit()}
                            style={{ marginRight: 0 }}
                            disabled={formLoader ? true : false}
                            className={formLoader && "disabledButton"}
                          >
                            {getTranslated('buttons.reviewSubmit') || 'Review & Submit'}
                          </ButtonSecondary>
                        )}
                        {userRole === "tr" ? (
                          <span></span>
                        ) : (
                          <ButtonSecondary
                            onClick={() => handleNextAndSubmit(true)}
                            disabled={formLoader ? true : false}
                            className={formLoader && "disabledButton"}
                          >
                            {getTranslated('buttons.saveDraft') || 'Save Draft'}
                          </ButtonSecondary>
                        )}
                        <ButtonSecondary
                          onClick={() => handleExit()}
                          disabled={formLoader ? true : false}
                          className={formLoader && "disabledButton"}
                        >
                          {getTranslated('buttons.exit') || 'Exit'}
                        </ButtonSecondary>
                        {paqActiveStep > 0 ? (
                          <ButtonPrimary
                            onClick={() => handlePrev()}
                            disabled={formLoader ? true : false}
                            className={formLoader && "disabledButton"}
                          >
                            {getTranslated('buttons.prev') || 'Previous'}
                          </ButtonPrimary>
                        ) : (
                          ""
                        )}
                        {hasAccessToLastPage ? (
                          paqActiveStep === steps.length - 1 ? (
                            <ButtonPrimary
                              onClick={() => handleLastNext()}
                              style={{ marginRight: 0 }}
                              disabled={formLoader ? true : false}
                              className={formLoader && "disabledButton"}
                            >
                              {getTranslated('buttons.next') || 'Next'}
                            </ButtonPrimary>
                          ) : (
                            <ButtonPrimary
                              onClick={() => handleNextForTR()}
                              style={{ marginRight: 0 }}
                              disabled={formLoader ? true : false}
                              className={formLoader && "disabledButton"}
                            >
                              {getTranslated('buttons.next') || 'Next'}
                            </ButtonPrimary>
                          )
                        ) : (
                          <ButtonPrimary
                            onClick={() => handleNextAndSubmit()}
                            style={{ marginRight: 0 }}
                            disabled={formLoader ? true : false}
                            className={formLoader && "disabledButton"}
                          >
                            {getTranslated('buttons.next') || 'Next'}
                          </ButtonPrimary>
                        )}
                      </ButtonsContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
          </div>
        </Container>
      </div>
    )
  ) : (
    <div></div>
  )}
  </React.Fragment>;
};
export default Paq;
