import { isOtherOptionSelected } from "../../app/functions/isOtherOptionSelected";

const validate = (value: any) => {
  if (value !== null && value !== "" && value !== 0) {
    return true;
  } else {
    return false;
  }
};

const validateNumberFields = (value: any) => {
  if (value !== null && value !== "") {
    return true;
  } else {
    return false;
  }
};
export const positionDetailsValidate = (payload: any) => {
  let requiredProps = [
    "purposeOfRequestId",
    "newManagerGpid",
    "newManagerName",
    "proposedLevelId",
    "effectiveDate",
    "functionCode",
    "subFunctionCode",
    "proposedJobTitleId",
    "countryCode",
    "sectorCode",
    "divisionCode",
    "regionCode",
    "businessUnitCode",
    "marketUnitCode",
    "workLocationCode",
  ];
  let result = true;
  requiredProps.forEach(function (item, index) {
    if (!validate(payload[item])) {
      result = false;
    }
  });

  return result;
};

export const roleContextValidate = (payload: any, paqMasterData: any) => {

  let requiredProps = [
    "rolePurpose",
    "businessContextForRoleReevaluationId",
    "otherDescription",
    "isManagerAligned",
    "isAnotherRole",
  ];
  let result = true;
  requiredProps.forEach(function (item, index) {
    if (!validate(payload[item])) {
      result = false;
    }
  });

  if (!validate(payload.coreAccountability[0])) return false;

  if (
    isOtherOptionSelected(
      paqMasterData.stepTwo.businessContexts,
      payload.businessContextForRoleReevaluationId
    )
  ) {
    if (!validate(payload.otherDescription)) return false;
  }

  if (payload.isAnotherRole == true) {
    if (!validate(payload.workDelivery)) return false;
  }
  return result;
};

export const heirarchyValidate = (payload: any) => {
  let requiredProps = [
    "futureStateOrgChartFileName",
    "managerGPID",
    "managerEvaluatedLevelId",
  ];

  let requiredNumberProps=[
    "numberOfDirectReports",
    "numberOfMatrixReport",
    "headCount",
  ]

  let result = true;
  requiredProps.forEach(function (item, index) {
    if (!validate(payload[item])) {
      result = false;
    }
  });
  requiredNumberProps.forEach(function (item, index) {
    if (!validateNumberFields(payload[item])) {
      result = false;
    }
  });

  return result;
};
