import * as React from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import { updateRoleContextFormData, updateComments, updateRoleContextStaticFormData, getRoleContextMaster, submitCurrentOrganizationChart, submitFutureOrganizationChart } from "../../fmOneSlice";
import FormCheckBox from "../../../../../app/form-inputs/FormCheckBox";
import FormRadio from "../../../../../app/form-inputs/FormRadio";
import { useSearchParams } from "react-router-dom";
import FormTextAreaFullWidth from "../../../../../app/form-inputs/FormTextAreaFullWidth";
import { TOOLTIPS } from "../../../../../app/constants";
import FormSelect from "../../../../../app/form-inputs/FormSelect";
import FormAttachment from "../../../../../app/form-inputs/FormAttachment";
import { FmFranchise } from "../../FmFranchise";
import { getTranslated } from "../../../../../app/functions/getTranslatedLevel";
import FormNumber from "../../../../../app/form-inputs/FormNumber";

interface IFmRoleContext {
  updateFormData: Function;
  disabled?: boolean;
}

const FmRoleContext = ({ updateFormData, disabled = false }: IFmRoleContext) => {
  const [queryParams] = useSearchParams();
  let fmType = queryParams.get("fmType");
  const fmState = useSelector((state: any) => state.fm);
  const roleContextForm = useSelector((state: any) => state.fm.roleContextForm);
  const formData = useSelector((state: any) => state.fm.formData.roleContext);
  const functionValue = useSelector((state: any) => state.fm.formData.positionDetails.functionCode);
  const formStaticData = useSelector((state: any) => state.fm.formData.roleContextStaticFields);
  const formLockedFromState = useSelector((state: any) => state.fm.formLocked);
  const formLocked = formLockedFromState || disabled;
  const masterData = useSelector((state: any) => state.fm.masterData.roleContext);

  const searchForRadio = (criteriaId: any) => {
    let obj = formData.find((o: any) => o.criteriaId === criteriaId);
    return obj !== undefined ? obj.answerId : 0;
  };

  const updateState = (payload: object) => {
    updateFormData(updateRoleContextFormData(payload));
  };
  const updateStaticStateData = (payload: object) => {
    updateFormData(updateRoleContextStaticFormData(payload));
  }
  const updateRoleContextComments = (payload: object) => {
    updateFormData(updateComments(payload));
  };
  //Onload use effect
  React.useEffect(() => {
    //1. drop down generation
    updateFormData(getRoleContextMaster());
  }, []);

  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={12} md={6} p={3}>
          <FormTextAreaFullWidth
            lines={3}
            maxLength={800}
            data={{
              disabled: formLocked,
              label: getTranslated("step2.rolePurpose") || "Role Purpose",
              value: formStaticData.rolePurpose,
              options: [],
              name: "rolePurpose",
              required: true,
              toolTipText: getTranslated("tooltips.rolePurpose") || TOOLTIPS.rolePurpose,
            }}
            updateState={updateStaticStateData}
            state={fmState}
          />
          <div className="textField-gap">
            <FormTextAreaFullWidth
              lines={2}
              data={{
                disabled: formLocked,
                label: getTranslated("step2.comparableRoles") || "Comparable Roles (optional)",
                value: formStaticData.comparableRoles,
                name: "comparableRoles",
              }}
              updateState={updateStaticStateData}
              state={fmState}
            />
          </div>
        </Grid>
        <Grid item xs={12} md={6} p={3}>
          <FormSelect
            data={{
              label:
                getTranslated("step2.businessContextForRoleReevaluationId") || "Please select the business context that is the primary driver for re-evaluating this role",
              value: formStaticData.businessContextForRoleReevaluationId,
              options: masterData.businessContexts,
              name: "businessContextForRoleReevaluationId",
              readOnly: formLocked,
              required: true,
            }}
            updateState={updateStaticStateData}
          />

          <div className="textField-gap">
            <FormTextAreaFullWidth
              lines={2}
              data={{
                label: getTranslated("step2.otherDescription") || "Please provide more details on the business context",
                value: formStaticData.otherDescription,
                name: "otherDescription",
                required: true,
                disabled: formLocked,
              }}
              updateState={updateStaticStateData}
              state={fmState}
            />
          </div>
        </Grid>
      </Grid>
      {/* Render for functionValue corresponding to Franchise-- JF009 */}
      {functionValue == 'JF009' && <FmFranchise updateStaticStateData={updateStaticStateData} formLocked={formLocked} formStaticData={formStaticData} masterData={masterData} />}
      <Grid container>
        {roleContextForm &&
          roleContextForm.map((section: any) => (
            <React.Fragment key={section.framework}>
              <Grid item xs={12} md={12} lg={12} mt={2} mb={1}>
                <span
                  style={{
                    backgroundColor: "lightgray",
                    padding: "5px 40px 5px 40px",
                    borderRadius: "5px",
                    fontSize: "110%",
                  }}
                >
                  {/* <i>{section.framework}</i> */}
                  <i>{getTranslated(`dynamic.${section.framework}`) || section.framework}</i>
                </span>
              </Grid>
              {section.subFramework.map((sub: any) =>
                sub.criterias.map((criteria: any) => (
                  <React.Fragment key={criteria.criteriaCode}>
                    {criteria.answerType === "Radio" ? (
                      <Grid
                        key={criteria.criteriaCode}
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        p={3}
                      >
                        <FormRadio
                          data={{
                            options: criteria.options,
                            criteriaInfo: criteria.criteriaInfo,
                            criteriaTooltip: criteria.criteriaTooltip,
                            criteriaCode: criteria.criteriaCode,
                            value: searchForRadio(criteria.criteriaCode),
                            readOnly: formLocked,
                            fmFormType: 'fm',
                          }}
                          updateState={updateState}
                        />
                      </Grid>
                    ) : fmType === "fm3" ? (
                      <Grid
                        key={criteria.criteriaCode}
                        item
                        xs={12}
                        md={12}
                        lg={12}
                        p={3}
                      >
                        <FormCheckBox
                          data={{
                            options: criteria.options,
                            criteriaInfo: criteria.criteriaInfo,
                            criteriaTooltip: criteria.criteriaTooltip,
                            criteriaCode: criteria.criteriaCode,
                            readOnly: formLocked,
                            fmFormType: 'fm'
                          }}
                          updateState={updateState}
                        />
                      </Grid>
                    ) : (
                      <Grid
                        key={criteria.criteriaCode}
                        item
                        xs={12}
                        md={6}
                        lg={6}
                        p={3}
                      >
                        <FormCheckBox
                          data={{
                            options: criteria.options,
                            criteriaInfo: criteria.criteriaInfo,
                            criteriaTooltip: criteria.criteriaTooltip,
                            criteriaCode: criteria.criteriaCode,
                            readOnly: formLocked,
                            fmFormType: 'fm'
                          }}
                          updateState={updateState}
                        />
                      </Grid>
                    )}
                  </React.Fragment>
                ))
              )}
            </React.Fragment>
          ))}
        <Grid item xs={12} md={6} lg={6} mt={2} p={3}>
          <FormNumber
            data={{
              label: getTranslated("step4.numberOfDirectReports") || "Number of Direct Reports",
              value: formStaticData.numberOfDirectReports,
              name: "numberOfDirectReports",
              readOnly: formLocked,
              required: true,
            }}
            updateState={updateStaticStateData}
          />
          <div className="textField-gap">
            <b>{getTranslated("step3.comment") || "Comments (if any)"}</b>
          </div>
          <FormTextAreaFullWidth
            lines={4}
            data={{
              disabled: formLocked,
              label: "",
              value: fmState.comments,
              name: "comments",
              placeholder:
                getTranslated("placeHolder.comments") || "Use the following space to leave any comments regarding the role",
            }}
            updateState={updateRoleContextComments}
            state={fmState}
          />
        </Grid>
        <Grid item xs={12} md={6} lg={6} mt={2} p={3}>
          <FormAttachment
            data={{
              state: fmState,
              label: getTranslated("step4.currentStateOrgChartFileName") || "Please upload the current state organization chart",
              value: formStaticData.currentStateOrgChartFileName,
              name: "currentStateOrgChartFileName",
              required: true,
              readOnly: formLocked,
              toolTipText: getTranslated("tooltips.currentStateOrgChartFileName") || TOOLTIPS.currentStateOrgChartFileName,
            }}
            updateState={updateStaticStateData}
            uploadFile={submitCurrentOrganizationChart}
          />
          <FormAttachment
            data={{
              state: fmState,
              label: getTranslated("step4.futureStateOrgChartFileName") || "Please upload the future state organization chart",
              value: formStaticData.futureStateOrgChartFileName,
              name: "futureStateOrgChartFileName",
              required: true,
              readOnly: formLocked,
              toolTipText: getTranslated("tooltips.futureStateOrgChartFileName") || TOOLTIPS.futureStateOrgChartFileName,
            }}
            updateState={updateStaticStateData}
            uploadFile={submitFutureOrganizationChart}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};
export default FmRoleContext;
