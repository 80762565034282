export const isOtherOptionSelected = (
  contextArray: any[],
  selectedValue: any
) => {
  let res = false;
  let mapArray = [];
  if (contextArray != undefined) {
    mapArray = contextArray;
  }
  mapArray.map((context) => {
    if (context.value == selectedValue && context.name == "Others") res = true;
  });
  return res;
};
