import React from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Header from "./features/header/Header";
import Paq from "./features/paq/Paq";
import PaqReEvaluate from "./features/paqReEvaluate/PaqReEvaluate";
import PaqEvaluateNewRole from "./features/paqEvaluateNewRole/PaqEvaluateNewRole";
import FmOne from "./features/functionalModels/fm_1/FmOne";
import FmPaqReEvaluate from "./features/functionalModels/fmPaqReEvaluate/FmOne";
import FmPaqEvaluateNewRole from "./features/functionalModels/fmPaqEvaluateNewRole/FmOne";
import { RequiredAuth } from "./okta/SecureRoute";
import { oktaConfig } from "./okta/Okta.Config";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { LoginCallback, Security } from "@okta/okta-react";
// import { ConvertJson } from "./app/form-preview/ConvertJson";

const oktaAuth = new OktaAuth(oktaConfig);

const App = () => {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: any, originalUri: any) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Routes>
        <Route path="/paq" element={<RequiredAuth />}>
          <Route path="" element={<Paq />}></Route>
        </Route>
        <Route path="/paq-re-evaluate" element={<RequiredAuth />}>
          <Route path="" element={<PaqReEvaluate />}></Route>
        </Route>
        <Route path="/paq-evaluate-new-role" element={<RequiredAuth />}>
          <Route path="" element={<PaqEvaluateNewRole />}></Route>
        </Route>
        <Route path="/fm" element={<RequiredAuth />}>
          <Route path="" element={<FmOne />}></Route>
        </Route>
        <Route path="/fmReEvaluate" element={<RequiredAuth />}>
          <Route path="" element={<FmPaqReEvaluate />}></Route>
        </Route>
        <Route path="/fmEvaluateNewRole" element={<RequiredAuth />}>
          <Route path="" element={<FmPaqEvaluateNewRole />}></Route>
        </Route>
        <Route path="login/callback" element={<LoginCallback />} />
        {/* <Route path="/json" element={<ConvertJson />}></Route> */}
        <Route path="*" element={<Paq />} />
      </Routes>
    </Security>
  );
};

export default App;
