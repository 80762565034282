import * as React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { FormControlFlex } from "../styled";
import { ToolTip } from "./ToolTip";
import { FieldsToTranslate } from "../constants";
import { InputAdornment } from "@mui/material";
import { getTranslated } from "../functions/getTranslatedLevel";
import PaqSubmitDialog from "../../app/dialog/PaqSubmitDialog";


interface IFormText {
  updateState: Function;
  data: any;
  state?: any;
  isCurrencyField?:boolean
  isNumericField?:boolean
  isHidden?:boolean
}

const FormText = ({ updateState, data, state, isCurrencyField=false, isNumericField=false, isHidden=false }: IFormText) => {
  const language = state?.language;

  const translate = () => {
    let text = { q: data.value };
    let url = `https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_API_KEY}`;
    url += `&target=${language}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(text),
    })
      .then((res) => res.json())
      .then((response) => {
        const translatedValue = response.data.translations[0].translatedText;
        const detectedSourceLanguage =
          response.data.translations[0].detectedSourceLanguage;
        if (detectedSourceLanguage !== language) {
          updateState({
            [data.name]: translatedValue,
          });
        }
      })
      .catch((error) => {
        console.log("error ", error);
      });
  };

  React.useEffect(() => {
    if (
      data.name === "purposeOfRequestId" ||
      (state?.oktaUserRole === "tr" && FieldsToTranslate.includes(data.name))
    ) {
      if (state) {
        translate();
      }
    }
  }, []);
  
  /*
  props .data

  {
    label: "Purpose of request",
    value: "lore ipsum",
    name:'purpose_of_request',
  }
  */
  //local State for error
  const [validationError, setValidationError] = React.useState(false);
  const [regexError, setRegexError] = React.useState(false);
  //handle Modal variables and methods - START
  const [isError, setIsError] = React.useState<boolean>(false);
  const [errMsg, setErrMsg] = React.useState<string>("");
  const handleModalOpen = (msg: string) => {
    setIsError(true)
    setErrMsg(msg);
  }
  const handleModalClose = () => {
    setErrMsg("");
    setIsError(false)
  }
  //handle Modal variables and methods - END
  const handleChange = (key: any, event: any) => {
    let value = event.target.value;
    if (data.noNumeric) value = value.replace(/[0-9]/g, "");
    if (isCurrencyField || isNumericField) {
      value = value.replaceAll(/[^0-9.]/g, "");
      // console.log(value);
    }
    if(key=='totalBeverageVolume'||key=='nopbt'){
      if(value>3000){
        handleModalOpen(getTranslated('step2.pleaseInputValueBetween') || "Please input a value between 0 and 3000!")
      }else{
        updateState({
        [key]: value,
        });
      }
    }else{
      updateState({
      [key]: value,
      });
    }
  };

  React.useEffect(() => {
    //required check
    if (data.required != undefined && data.required === true) {
      if (
        data.value !== undefined &&
        data.value !== null &&
        data.value !== ""
      ) {
        setValidationError(false);
      } else {
        setValidationError(true);
      }
    }

    //regex check
    if (data.regex != undefined && data.regex != "") {
      if (
        data.value !== undefined &&
        data.value !== null &&
        data.value !== ""
      ) {
        let pattern = new RegExp(data.regex);
        let result = pattern.test(data.value);
        if (result) {
          setRegexError(false);
          setValidationError(false);
        } else {
          setRegexError(true);
          setValidationError(true);
        }
      } else {
        if (data.required != undefined && data.required === true) {
          setRegexError(false);
          setValidationError(true);
        } else {
          setRegexError(false);
          setValidationError(false);
        }
      }
    }
  }, [data]);

  const InputProps={
    startAdornment: (
      <InputAdornment position="start">
        $
      </InputAdornment>
    ),
    endAdornment: (
      <InputAdornment position="end">
        MM USD
      </InputAdornment>
    ),
  }

  return (
    <>
      {!isHidden && <FormControlFlex >
        {isError && <PaqSubmitDialog customMsg={errMsg} handleModalClose={handleModalClose} />}
        <div className="label">
          <span>
            {data.label}
            {data.required != undefined && data.required === true && (
              <span style={{ color: "white", marginLeft: 5 }}>*</span>
            )}
          </span>

          {data.toolTipText != undefined ? (
            <ToolTip title={data.toolTipText} />
          ) : (
            ""
          )}
        </div>
        <div>
          <FormControl fullWidth>
            <TextField
              disabled={
                data.readOnly != undefined && data.readOnly === true
                  ? true
                  : false
              }
              multiline={data.multiline}
              error={validationError}
              size="small"
              id="outlined-basic"
              label=""
              variant="outlined"
              value={data.value || ""}
              onInput={(e) => handleChange(data.name, e)}
              inputProps={{ maxLength: 256 }}
              InputProps={isCurrencyField ? InputProps:undefined}
              title={data.value || ""}
              required
            />
            {regexError && (
              <small className="errorMessage">{data.label} is not valid</small>
            )}
          </FormControl>
        </div>
      </FormControlFlex>}
    </>
  );
};
export default FormText;
