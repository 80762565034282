import * as React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { ToolTip } from "./ToolTip";
import { FieldsToTranslate } from "../constants";
import { getTranslated } from "../functions/getTranslatedLevel";

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

interface IFormTextAreaFullWidth {
  updateState: Function;
  data: any;
  lines: number;
  maxLength?: number;
  state?: any
}

const FormTextAreaFullWidth = ({
  updateState,
  data,
  lines,
  maxLength,
  state
}: IFormTextAreaFullWidth) => {
  const language = state?.language;
  const [translationDone, setTranslationDone] = React.useState(false);

  const translate = () => {
    let text = { q: data.value };
    let url = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;
    url += `&target=${language}`;

    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(text),
    })
      .then((res) => res.json())
      .then((response) => {
        const translatedValue = response.data.translations[0].translatedText;
        const detectedSourceLanguage =
          response.data.translations[0].detectedSourceLanguage;
        if (detectedSourceLanguage !== language) {
          updateState({
            [data.name]: translatedValue,
          });
        }
      })
      .catch((error) => {
        console.log("error ", error);
      });
  };

  React.useEffect(() => {
    if (
      data.value && translationDone === false &&
      state?.oktaUserRole === "tr" && FieldsToTranslate.includes(data.name)
    ) {
      setTranslationDone(true);
      translate();
    }
  }, [data.value]);
  /*
  props .data

  
  {
    label: "Purpose of request",
    value: "lore ipsum",
    name:'purpose_of_request',
  }
  */
  const [validationError, setValidationError] = React.useState(false);
  const [remainningChar, setRemainningChar] = React.useState(
    maxLength ? maxLength : 800
  );

  const handleChange = (key: any, event: any) => {
    let lines = event.target.value.split(/\r|\r\n|\n/);
    let count = lines.length;
    if (count >= 11) {
      return false;
    }
    //Get remainning charecter
    let charCount = event.target.value.length;
    let maxLimit = maxLength ? maxLength : 800;
    setRemainningChar(maxLimit - charCount);

    //Update data
    updateState({
      [key]: event.target.value,
    });
  };

  React.useEffect(() => {
    if (data.required != undefined && data.required === true) {
      if (
        data.value !== undefined &&
        data.value !== null &&
        data.value !== ""
      ) {
        setValidationError(false);
      } else {
        setValidationError(true);
      }
    }

    //set remainning charecter
    if (data.value && data.value != "") {
      let charCount = data.value.length;
      let maxLimit = maxLength ? maxLength : 800;
      setRemainningChar(maxLimit - charCount);
    }
  }, [data]);

  return (
    <>
      <div className="label">
        <span>{data.label}</span>
        {data.required != undefined && data.required === true && (
          <span style={{ color: "white", marginLeft: 5 }}>*</span>
        )}
        {data.toolTipText != undefined ? (
          <ToolTip title={data.toolTipText} />
        ) : (
          ""
        )}
      </div>
      <div style={{ marginTop: 5 }}>
        <FormControl fullWidth>
          <TextField
            disabled={data.disabled ? true : false}
            error={validationError}
            id="outlined-basic"
            label=""
            variant="outlined"
            placeholder={data.placeholder}
            value={data.value || ""}
            onInput={(e) => handleChange(data.name, e)}
            multiline
            maxRows={10}
            minRows={lines}
            inputProps={{
              maxLength: maxLength ? maxLength : 800,
            }}
          />
        </FormControl>
        <div className={`input-defination`}>
          {`${getTranslated('others.maximum') || "Maximum"} ${maxLength ? maxLength : 800} ${getTranslated('others.characters') || "characters"} (${remainningChar}
           ${getTranslated('others.remain') || "remaining"})`}
        </div>
      </div>
    </>
  );
};
export default FormTextAreaFullWidth;
