import React from "react";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";
import {
  updateStepTwoFormData,
  getRoleContextMaster,
} from "../../paqEvaluateNewRoleSlice";
import FormSelect from "../../../../app/form-inputs/FormSelect";
import FormTextRepeat from "../../../../app/form-inputs/FormTextRepeat";
import { isOtherOptionSelected } from "../../../../app/functions/isOtherOptionSelected";
import { TOOLTIPS, TRUE_FALSE_OPTIONS } from "../../../../app/constants";
import FormTextAreaFullWidth from "../../../../app/form-inputs/FormTextAreaFullWidth";
import FormText from "../../../../app/form-inputs/FormText";
import { getTranslated } from "../../../../app/functions/getTranslatedLevel";

interface IPaqStepTwo {
  updateFormData: Function;
  disabled?: boolean;
}

const PaqStepTwo = ({ updateFormData, disabled = false }: IPaqStepTwo) => {
  //States
  const formData = useSelector(
    (state: any) => state.paqEvaluateNewRole.formData.stepTwo
  );
  const state = useSelector(
    (state: any) => state.paqEvaluateNewRole
  );
  const masterData = useSelector(
    (state: any) => state.paqEvaluateNewRole.masterData.stepTwo
  );
  const formLockedFromState = useSelector(
    (state: any) => state.paqEvaluateNewRole.formLocked
  );
  const formLocked = formLockedFromState || disabled;
  //step two dispatch
  const updateState = (payload: object) => {
    updateFormData(updateStepTwoFormData(payload));
  };

  //Onload use effect
  React.useEffect(() => {
    //1. drop down generation
    updateFormData(getRoleContextMaster());
    console.log("paq3 form data-->", formData);
  }, []);

  React.useEffect(() => {
    if (
      formData.isAnotherRole !== undefined &&
      formData.isAnotherRole === false
    ) {
      updateState({ workDelivery: null });
    }
  }, [formData.isAnotherRole]);

  return (
    <>
      <div className="step-2-wrapper">
        <Grid container spacing={10}>
          <Grid item xs={12} md={12} lg={12}>
            <Grid container spacing={10}>
              <Grid item xs={12} md={6}>
                <FormTextAreaFullWidth
                  lines={3}
                  maxLength={800}
                  data={{
                    disabled: formLocked,
                    label: getTranslated("step2.rolePurpose") || "Role Purpose",
                    value: formData.rolePurpose,
                    options: [],
                    name: "rolePurpose",
                    step: "stepTwo",
                    required: true,
                    toolTipText: getTranslated("tooltips.rolePurpose") || TOOLTIPS.rolePurpose,
                  }}
                  updateState={updateState}
                  state={state}
                />
                <div className="textField-gap">
                  <FormTextAreaFullWidth
                    lines={2}
                    data={{
                      disabled: formLocked,
                      label: getTranslated("step2.comparableRoles") || "Comparable Roles",
                      value: formData.comparableRoles,
                      name: "comparableRoles",
                    }}
                    updateState={updateState}
                    state={state}
                  />
                </div>
                <FormTextRepeat
                  data={{
                    disabled: formLocked,
                    label: getTranslated("step2.coreAccountability") || "Core Accountabilities",
                    placeholder: getTranslated("placeHolder.coreAccountability") || "Accountability",
                    value: formData.coreAccountability,
                    name: "coreAccountability",
                    options: [],
                    noOfInput: 6,
                    required: true,
                    toolTipText: getTranslated("tooltips.coreAccountability") || TOOLTIPS.coreAccountability,
                  }}
                  updateState={updateState}
                  state={state}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <FormSelect
                  data={{
                    label:
                      getTranslated("step2.businessContextForRoleReevaluationIdForNewRole") || "Please select the business context that is the primary driver for creating this new role",
                    value: formData.businessContextForRoleReevaluationId,
                    options: masterData.businessContexts,
                    name: "businessContextForRoleReevaluationId",
                    readOnly: formLocked,
                    required: true,
                  }}
                  updateState={updateState}
                />

                <div style={{ marginTop: 15, marginBottom: 15 }}>
                  <FormTextAreaFullWidth
                    lines={2}
                    data={{
                      label:
                        getTranslated("step2.otherDescription") || "Please provide more details on the business context",
                      value: formData.otherDescription,
                      name: "otherDescription",
                      required: true,
                      disabled: formLocked,
                    }}
                    updateState={updateState}
                    state={state}
                  />
                </div>

                <FormSelect
                  data={{
                    label: getTranslated("step2.isAnotherRole") || "Is work coming to this role from another role ?",
                    value: formData.isAnotherRole,
                    options: TRUE_FALSE_OPTIONS,
                    name: "isAnotherRole",
                    readOnly: formLocked,
                    required: true,
                  }}
                  updateState={updateState}
                />
                {formData.isAnotherRole == true ? (
                  <div style={{ marginTop: 15 }}>
                    <FormTextAreaFullWidth
                      lines={1}
                      data={{
                        disabled: formLocked,
                        label:
                          getTranslated("step2.workDelivery") || "If Yes, where was this work being delivered before this change ?",
                        value: formData.workDelivery,
                        options: [],
                        name: "workDelivery",
                        step: "stepTwo",
                        required: true,
                        toolTipText: getTranslated("tooltips.workDelivery") || TOOLTIPS.workDelivery,
                      }}
                      updateState={updateState}
                      state={state}
                    />
                  </div>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default PaqStepTwo;
